import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import iconAllLaunches from '../icons/all-launch.png'
import iconFairLaunches from '../icons/fair-launch.png'
import iconTokerCreator from '../icons/token-creator.png'
import iconMyContributions from '../icons/my-contributions.png'
import iconTutorials from '../icons/tutorials.png'
import iconDocuments from '../icons/documents.png'
// import iconCompetitions from '../icons/competitions.png'
import MoonBoard from '../icons/MoonBoard.png'
import MoonPush from '../icons/MoonPush.png'
import MoonBot from '../icons/MoonBot.png'
import iconTelegram from '../icons/telegram1.png'
import lockIcon from '../icons/lock-icon.png'
import iconX from '../icons/x.png'
import classnames from 'classnames'

const LeftBar = ({ isExpanded, onHamburgerClick }) => {
  const [showLinks] = useState(true)
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  let currentPath = window.location.pathname
  const textClass = classnames('left-bar-text', {
    'left-bar-text-active': isExpanded || isHovered
  })

  return (
    <div
      className={`left-bar ${isExpanded ? 'left-bar-expanded' : ''}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      onClick={onHamburgerClick}
    >
      <ul className="left-bar-links">
        {(isHovered || showLinks) && (
          <>
            <li>
              <Link to="/AllLaunches" className="left-bar-link">
                <img
                  src={iconAllLaunches}
                  alt="All Launches"
                  className="left-bar-icon"
                />
                <span
                  className={
                    currentPath === '/AllLaunches'
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >
                  All&nbsp;Launches
                </span>
              </Link>
            </li>
            <li>
              <Link to="/FairLaunch" className="left-bar-link">
                <img
                  src={iconFairLaunches}
                  alt="Fair Launches"
                  className="left-bar-icon"
                />
                <span
                  className={
                    currentPath === '/FairLaunch'
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >
                  Fair&nbsp;Launch
                </span>
              </Link>
            </li>
            {/* <li>
              <div className="left-bar-link">
                <div className="left-bar-sub-link">
                  <img
                    src={iconFairLaunches}
                    alt="Fair Launches"
                    className="left-bar-icon"
                  />
                  <span
                    className={
                      currentPath === '/FairLaunch'
                        ? 'listSelected ' + textClass
                        : 'listUnselected ' + textClass
                    }
                  >Fair&nbsp;Launch</span>
                </div>
                <img
                  src={lockIcon}
                  alt="Lock Icon"
                  className="left-bar-icon-lock"
                />
              </div>
            </li> */}
            <li>
              <Link to="/" className="left-bar-link">
                <img
                  src={iconTokerCreator}
                  alt="Token Creator"
                  className="left-bar-icon"
                />
                <span
                  className={
                    currentPath === '/'
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >
                  Token&nbsp;Creator
                </span>
              </Link>
            </li>
            <li>
              <Link to="/MyContributions" className="left-bar-link">
                <img
                  src={iconMyContributions}
                  alt="My Contributions"
                  className="left-bar-icon"
                />
                <span
                  className={
                    currentPath === '/MyContributions'
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >
                  My&nbsp;Contributions
                </span>
              </Link>
            </li>
            <hr className="left-bar-hr" />
            <li>
              <Link to="/MoonBoard" className="left-bar-link">
                <img
                  src={MoonBoard}
                  alt="Moon Board"
                  className="left-bar-icon"
                  style={{ marginLeft: '18px' }}
                />
                <span
                  className={
                    currentPath === '/MoonBoard'
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >Moon&nbsp;Board</span>
              </Link>
            </li>
            <li>
              <div className="left-bar-link">
                <div className="left-bar-sub-link">
                  <img
                    src={MoonPush}
                    alt="Moon Push"
                    className="left-bar-icon"
                  />
                  <span className={`${textClass}`}>Moon&nbsp;Push</span>
                </div>
                <img
                  src={lockIcon}
                  alt="Lock Icon"
                  className="left-bar-icon-lock"
                />
              </div>
            </li>
            <li>
              <div className="left-bar-link">
                <div className="left-bar-sub-link">
                  <img
                    src={MoonBot}
                    alt="Moon Bot"
                    className="left-bar-icon"
                  />
                  <span className={`${textClass}`}>Moon&nbsp;Bot</span>
                </div>
                <img
                  src={lockIcon}
                  alt="Lock Icon"
                  className="left-bar-icon-lock"
                />
              </div>
            </li>
            <hr className="left-bar-hr" />
            <li>
              <div className="left-bar-link">
                <div className="left-bar-sub-link">
                  <img
                    src={iconTutorials}
                    alt="Tutorials"
                    className="left-bar-icon"
                    style={{ marginLeft: '18px' }}
                  />
                  <span className={`${textClass}`}>Tutorials</span>
                </div>
                <img
                  src={lockIcon}
                  alt="Lock Icon"
                  className="left-bar-icon-lock"
                />
              </div>
            </li>
            <li>
              <div className="left-bar-link">
                <div className="left-bar-sub-link">
                  <img
                    src={iconDocuments}
                    alt="Documents"
                    className="left-bar-icon"
                  />
                  <span className={`${textClass}`}>Documents</span>
                </div>
                <img
                  src={lockIcon}
                  alt="Lock Icon"
                  className="left-bar-icon-lock"
                />

              </div>
            </li>
            {/* <li>
              <div className="left-bar-link">
                <img
                  src={iconCompetitions}
                  alt="Competitions"
                  className="left-bar-icon"
                />
                <span className={`${textClass} gray`}>Competitions</span>
              </div>
            </li> */}
            <hr className="left-bar-hr" />
            <li>
              <a
                href="https://t.me"
                target="_blank"
                rel="noreferrer"
                className="left-bar-link"
              >
                <img
                  src={iconTelegram}
                  alt="Telegram"
                  className="left-bar-icon"
                  style={{ width: '22px' }}
                />
                <span className={textClass}>Telegram</span>
              </a>
            </li>
            <li>
              <a
                href="https://x.com"
                target="_blank"
                rel="noreferrer"
                className="left-bar-link"
              >
                <img src={iconX} alt="X" className="left-bar-icon" />
                <span className={textClass}>X.com</span>
              </a>
            </li>
          </>
        )}
      </ul>
    </div >
  )
}

export default LeftBar
