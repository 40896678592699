import React from 'react';

const Footer = () => {
  return (
   <section className="footer" style={{ marginTop: '-16px', marginBottom: '-16px' }}>
     <p>
       <br />
       <span className='disclaimer'>
         Disclaimer: MoonBase will never endorse or encourage that you invest in any of the projects listed and therefore, accept no liability for any loss occurred. It is the user(s) responsibility to do their own research and seek financial advice from a professional.
       </span>
     </p>
     <br />
     {/*<p>
       <span className="moonbase" style={{ fontSize: '36px', color: 'white' }}>
         MOONBASE
       </span>
     </p>*/}
     <br/>
     <p className='contactInfo'>
       Contact us:{' '}
       <a href="mailto:contact@moonbased.io">contact@moonbased.io</a>
       <br />
       <br />
       <br />
       &nbsp;
     </p>
   </section>
  );
};

export default Footer;