/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNetwork } from 'wagmi'
import '../App.css'
import MultiCallAbi from '../config/MultiCallAbi.json'
import '../styles/MainContainer.css'
import CoinCard from '../components/CoinCard'
import LaunchpadCard from '../components/LaunchpadCard'
import ClipLoader from 'react-spinners/ClipLoader'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'
import { Link } from 'react-router-dom'
import { sortBy } from 'lodash'

const publicClient = createPublicClient({
  chain: base,
  transport: http()
})
const App = () => {
  const multicallAddress = '0x4Ec30E9D706c881b0B6D67929025C77bA473214D'
  const { chain } = useNetwork()
  let [loading, setLoading] = useState(false)
  const [presaleLists, setPresaleListDatas] = useState([])
  const [allLists, setAllLists] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true)
        const mainInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'presaleInfo'
        })
        const otherInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'presaleOtherInfo'
        })
        const createdTimes = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'launchpadCreatedTimes'
        })
        const length = Number(mainInfo[0].length)
        if (length > 0) {
          let presaleListsDatas = []
          let presaleData
          for (let i = length - 1; i >= 0; i--) {
            const isLive = mainInfo[0][i]
            let status
            let presaleEndTimestamp = Number(mainInfo[1][i])
            if (isLive === true) {
              status = 1
            } else {
              if (Number(presaleEndTimestamp) < new Date().getTime() / 1000) {
                status = 2
              } else {
                status = 0
              }
            }
            let allIn = true
            let softCap
            const softCapAmount = Number(mainInfo[2][i])
            softCap = Number(softCapAmount) / 10 ** 18
            let progress
            const totalDepositedBalance = Number(mainInfo[3][i])
            progress =
              (Number(totalDepositedBalance) * 100) / Number(softCapAmount)
            let liquidity
            const liquidityPercent = Number(otherInfo[1][i])
            liquidity = Number(liquidityPercent)
            const presaleStartTime = Number(mainInfo[4][i])
            let saleStart = Number(presaleStartTime)

            const name = otherInfo[0][i]
            let bannerUrl = 'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-banner.png'
            let logoUrl = 'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-logo.png'
            presaleData = {
              state: status,
              AllIn: allIn,
              CapLimit: softCap,
              progress: progress,
              Liquidity: liquidity,
              saleStarts: saleStart,
              saleEnds: presaleEndTimestamp,
              tokenName: name,
              logoUrl: logoUrl,
              bannerUrl: bannerUrl,
              address: otherInfo[2][i],
              depositedAmount: totalDepositedBalance / 10 ** 18,
              info: mainInfo[5][i],
              createdTime: createdTimes[i],
              cancelled: mainInfo[6][i]
            }
            presaleListsDatas.push(presaleData)
          }
          setPresaleListDatas(presaleListsDatas)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    FetchData()
  }, [chain?.id])

  const [coinLists, setCoinListDatas] = useState([])
  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true)
        const mainInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'coinInfo'
        })
        const otherInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'coinOtherInfo'
        })
        const createdTimes = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'coinCreatedTimes'
        })
        const length = Number(mainInfo[0].length)
        if (length > 0) {
          let coinListsDatas = []
          let coinData
          for (let i = length - 1; i >= 0; i--) {
            if (otherInfo[0][i] === true) {
              let bannerUrl =
                'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-banner.png'
              let logoUrl =
                'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-logo.png'
              let lpBurnAmount = false
              if (Number(otherInfo[1][i]) > 9900) {
                lpBurnAmount = true
              }
              coinData = {
                address: otherInfo[2][i],
                tokenName: mainInfo[0][i],
                logoUrl: logoUrl,
                bannerUrl: bannerUrl,
                lpBurnAmount: lpBurnAmount,
                info: mainInfo[5][i],
                state: 1,
                lpETHAmounts: Number(mainInfo[4][i]) / (10 ** 18),
                createdTime: createdTimes[i]
              }
              coinListsDatas.push(coinData)
            } else {
              setLoading(false)
            }
          }
          setCoinListDatas(coinListsDatas)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    FetchData()
  }, [chain?.id])

  const [combinedLists, setCombinedLists] = useState([])

  useEffect(() => {
    let combineListDatas = [...coinLists, ...presaleLists];
    combineListDatas = sortBy(combineListDatas, ['createdTime']).reverse();
    setAllLists(combineListDatas);
    let last
    let length = combineListDatas.length;
    setTotalLength(length);
    let datas = [];
    if (length > 6) {
      last = 6
    } else {
      last = length
    }
    for (let i = 0; i < last; i++) {
      datas.push(combineListDatas[i])
    }
    setCurrentIndex(last)
    setCombinedLists(datas)
  }, [coinLists, presaleLists])

  const loadMoreLists = () => async () => {
    try {
      setLoading(true)
      let newLists = []
      let last
      const length = totalLength - currentIndex
      if (length >= 0) {
        if (length > 6) {
          last = currentIndex + 6
        } else {
          last = currentIndex + length
        }
        for (let i = currentIndex; i < last; i++) {
          newLists.push(allLists[i])
        }
        let newDatas = combinedLists.concat(newLists)
        setCombinedLists(newDatas)
        setCurrentIndex(last)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const shouldRenderCoinCard = item => {
    // Define criteria for which items should be rendered as CoinCard
    return 'lpBurnAmount' in item
  }

  const launchTypes = ['All Launches', 'Fair Launches', 'Stealth Launches']
  const statusFilters = ['Status', 'Upcoming', 'Closed', 'Live']
  const [statusFilter, setStatusFilter] = useState('Status')
  const history = useHistory()

  const handleLaunchTypeChange = type => {
    const paths = {
      'all launches': '/AllLaunches',
      'fair launches': '/FairLaunches',
      'stealth launches': '/CommunityFunded',
      'my contributions': '/MyContributions'
    }

    const path = paths[type.toLowerCase()]
    if (path) {
      history.push(path)
    }
  }

  const handleStatusFilterChange = status => {
    setStatusFilter(status)
  }

  const shouldRenderCard = item => {
    if (statusFilter.toLocaleLowerCase() === 'status') {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'upcoming' &&
      item.state === 0
    ) {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'live' &&
      item.state === 1
    ) {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'closed' &&
      item.state === 2
    ) {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'sale live' &&
      item.state === 3
    ) {
      return true
    }
    return false
  }

  function isMobile() {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  }

  let filteredDatas = combinedLists.filter(item => shouldRenderCard(item))
  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="navBar"></div>
          <div className="headerMargin" />
          <div className="MainDashboard">
            <>
              <section className="FairLaunchFlexLayout">
                <section className="">
                  <p style={{ textAlign: 'center' }}>
                    <Link to="/FairLaunch" className="create-token-button">
                      {/* Create&nbsp;Fair&nbsp;Launch */}
                      Create&nbsp;Fairlaunch
                    </Link>
                  </p>
                  <br />
                  {/* Search field */}
                  <section>
                    <div className="filter-row-offset">
                      <div className="filter-row">
                        <div className="filter-buttons-row">
                          <div className="filter-dropdown-row">
                            <select
                              className="launch-type-dropdown"
                              styles={{
                                control: styles => ({
                                  ...styles,
                                  backgroundColor: '#06021d',
                                  color: 'white'
                                }),
                                option: styles => ({
                                  ...styles,
                                  backgroundColor: '#3554A2',
                                  color: 'white'
                                }),
                                singleValue: styles => ({
                                  ...styles,
                                  color: 'white'
                                }),
                                input: styles => ({
                                  ...styles,
                                  caretColor: 'transparent'
                                }),
                                dropdownIndicator: styles => ({
                                  ...styles,
                                  color: '9900c8'
                                }),
                                placeholder: styles => ({
                                  gridArea: '1 / 1 / 2 / 3',
                                  color: '#777777',
                                  marginLeft: '2px',
                                  marginRight: '2px',
                                  boxSizing: 'border-box'
                                })
                              }}
                              onChange={e =>
                                handleLaunchTypeChange(e.target.value)
                              }
                            >
                              {launchTypes.map((type, index) => (
                                <option key={index} value={type.toLowerCase()}>
                                  {type}
                                </option>
                              ))}
                            </select>
                            <select
                              className="status-filter-dropdown"
                              onChange={e =>
                                handleStatusFilterChange(e.target.value)
                              }
                            >
                              {statusFilters.map((status, index) => (
                                <option
                                  key={index}
                                  value={status.toLowerCase()}
                                >
                                  {status}
                                </option>
                              ))}
                            </select>
                          </div>
                          {!isMobile() ?
                            <Link
                              to="/MyContributions"
                              className="my-contributions-button"
                            >
                              My&nbsp;Contributions
                            </Link>
                            :
                            <></>
                          }
                        </div>
                        <div className="search-field">
                          <SearchIcon className="search-field-icon" />
                          <input
                            className="searchField search-field-input"
                            type="text"
                            placeholder="Search token"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </div>
                  </section>

                  {combinedLists.length > 0 ? (
                    <>
                      <div className="launchpad-card-grid">
                        {combinedLists
                          .filter(item => shouldRenderCard(item))
                          .filter(item =>
                            item.tokenName
                              ?.toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((item, i) => {
                            if (shouldRenderCoinCard(item)) {
                              return (
                                <CoinCard
                                  key={i}
                                  tokenAddress={item.address}
                                  tokenName={item.tokenName}
                                  LogoUrl={
                                    <img
                                      src={item.logoUrl}
                                      className="claim-eth-logo"
                                    />
                                  }
                                  BannerUrl={item.bannerUrl}
                                  LpBurnAmount={item.lpBurnAmount}
                                  Info={item.info}
                                  LpETHAmount={item.lpETHAmounts}
                                />
                              )
                            } else {
                              return (
                                <LaunchpadCard
                                  key={i}
                                  status={item.state}
                                  RugProof={true}
                                  AllIn={item.AllIn}
                                  CapType="Minimum Raise"
                                  CapLimit={item.CapLimit}
                                  progress={item.progress}
                                  Liquidity={item.Liquidity}
                                  lockupTime="Automatic Burn 🔥"
                                  saleStarts={item.saleStarts}
                                  saleEnds={item.saleEnds}
                                  tokenName={item.tokenName}
                                  Logo={
                                    <img
                                      src={item.logoUrl}
                                      className="claim-eth-logo"
                                    />
                                  }
                                  Banner={item.bannerUrl}
                                  presaleAddress={item.address}
                                  depositedAmount={item.depositedAmount}
                                  Info={item.info}
                                  Cancelled={item.cancelled}
                                />
                              )
                            }
                          })}
                      </div>
                      {filteredDatas.length === 0 && (
                        <div className="loadingBox">
                          <p className="Nodatas Text1">
                            No datas yet
                          </p>
                        </div>
                      )}
                      {loading === true ? (
                        <div className="loadingBox">
                          <ClipLoader
                            color={'#afccc6'}
                            loading={true}
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (totalLength - currentIndex) > 0 ? (
                        <p className="loadMoreText" onClick={loadMoreLists()}>
                          Load more ...
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : totalLength === 0 || loading === false ? (
                    <div className="loadingBox">
                      <p className="Nodatas Text1">
                        No datas yet
                      </p>
                    </div>
                  ) : (
                    <div className="EmptyLaunchpad">
                      <div className="loadingBox">
                        <p className="Text1" style={{ color: 'black' }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={'#afccc6'}
                          loading={true}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </div>
                  )}
                  <br />
                  <br />
                  <br />
                  <br />
                </section>
              </section>
            </>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
