import React from "react";
import styles from "./App.module.scss";
import MainContainer from "./container/MainContainer.tsx";
import MyContributions from "./container/MyContributions";
import CommunityFunded from "./container/CommunityFunded";
import AllLaunches from "./container/AllLaunches";
import FairLaunches from "./container/FairLaunches";
import MoonBoard from "./container/MoonBoard";
import FairLaunch from "./container/FairLaunch.tsx";
import NotFound from "./container/NotFound";
import BuyPage from "./container/BuyPage";
import TokenPage from "./container/TokenPage";
import Profile from "./container/Profile";
import EditProfile from "./container/EditProfile.tsx";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { base, sepolia } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from "@web3modal/react";
import toast, { ToastBar, Toaster } from "react-hot-toast";

const projectId = '4807d388fe495226b7fc14743af2e1d9'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [base, sepolia],
  [w3mProvider({ projectId })],
)


export const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '4807d388fe495226b7fc14743af2e1d9',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains);

localStorage.setItem('wallet_address', '');
const App = () => {
  return (
    <Router>
      <QueryParamProvider>
        <div className={styles.App}>
          <WagmiConfig config={config}>
            <Toaster
              position="top-right"
              reverseOrder={true}
              toastOptions={{ duration: 5000 }}
            >
              {(t) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => toast.dismiss(t.id)}
                >
                  <ToastBar onClick={() => alert(1)} toast={t} />
                </div>
              )}
            </Toaster>
            <Switch>
              <Route exact path="/">
                <MainContainer />
              </Route>
              <Route exact path="/MyContributions">
                <MyContributions />
              </Route>
              <Route exact path="/CommunityFunded">
                <CommunityFunded />
              </Route>
              <Route exact path="/AllLaunches">
                <AllLaunches />
              </Route>
              <Route exact path="/FairLaunches">
                <FairLaunches />
              </Route>
              <Route exact path="/MoonBoard">
                <MoonBoard />
              </Route>
              <Route exact path="/FairLaunch">
                <FairLaunch />
                {/* <NotFound /> */}
              </Route>
              <Route exact path="/Buy">
                <BuyPage />
              </Route>
              <Route exact path="/Token">
                <TokenPage />
              </Route>
              <Route exact path="/Profile">
                <Profile />
              </Route>
              <Route exact path="/EditProfile">
                <EditProfile />
              </Route>
              <Route exact path="/NotFound">
                <NotFound />
              </Route>
            </Switch>
          </WagmiConfig>
          <Web3Modal
            projectId="4807d388fe495226b7fc14743af2e1d9"
            ethereumClient={ethereumClient}
          />
        </div>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
