/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAccount } from 'wagmi'
import '../App.css'
import '../styles/MainContainer.css'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer.jsx'
import TopBar from '../components/TopBar.jsx'
import ProfileUploadBox from '../components/ProfileUploadBox'
import ClipLoader from 'react-spinners/ClipLoader'

const EditProfile = () => {
  const BackIcon: string = require('../icons/back.svg').default;
  const { address } = useAccount()
  const history = useHistory()
  const [bannerPreview, setBannerPreview] = useState<string | null>()
  const [bannerFile, setBannerFile] = useState<File | null>(null)
  const bannerFileInput = useRef<HTMLInputElement>(null)
  let [loading, setLoading] = useState(false)

  const [userName, setUserName] = useState('')
  const [userBio, setUserBio] = useState('')
  useEffect(() => {
    const FetchInfo = async () => {
      try {
        await fetch(`https://base-fairlaunch-api-0df02e9f3f15.herokuapp.com/api/getOne/${address}`, {
          method: 'GET'
        })
          .then(async res => {
            let data = await res.json()
            if (data.length > 0) {
              if (data[0].imageUrl === 'default') {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              } else {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/' + data[0].imageUrl)
              }
              setUserName(data[0].name)
              setUserBio(data[0].content)
            } else {
              setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              setUserName('@mooning')
              setUserBio('Just mooning it out')
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (address) {
      FetchInfo()
    }
  }, [address])


  const BannerImageUpload = ({ onChange, className, style }) => {
    const handleBannerImageChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const selectedFile = e.target.files![0]
      setBannerFile(selectedFile)
      setBannerPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (bannerFileInput.current) {
        bannerFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={bannerFileInput}
          accept="image/*"
          onChange={handleBannerImageChange}
          style={{ display: 'none' }}
        />
        <ProfileUploadBox
          imageUrl={bannerPreview}
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }

  const [, setImageBannerFile] = useState(null)
  const handleImageBannerChange = file => {
    setImageBannerFile(file)
  }

  const onSave = async () => {
    try {
      setLoading(true)
      let bannerUrl
      if (bannerFile) {
        const formData = new FormData()
        formData.append('file', bannerFile, address)
        fetch('https://moonbase.zapto.org:13001/profileUploads', {
          method: 'POST',
          body: formData
        })
          .then(async res => {
            console.log('Success:', res)
            bannerUrl = await res.json()
            bannerUrl = bannerUrl.fileInfo.filename

            const sendData = {
              profileAddress: address,
              name: userName,
              content: userBio,
              imageUrl: bannerUrl
            }
            const response = await fetch('https://base-fairlaunch-api-0df02e9f3f15.herokuapp.com/api/add', {
              method: 'POST',
              mode: 'cors',
              cache: 'no-cache',
              headers: {
                'Content-Type': 'application/json'
              },
              redirect: 'error',
              body: JSON.stringify(sendData)
            })

            if (response.status !== 200) {
              const { error } = await response.json()
              throw new Error(error)
            }
            localStorage.setItem('wallet_address', '');
            toast.success(`Updated`)
            setLoading(false)
            window.location.reload();
          })
          .catch(error => {
            setLoading(false)
            console.error('Error:', error)
          })
      } else {
        const sendData = {
          profileAddress: address,
          name: userName,
          content: userBio,
          imageUrl: 'default'
        }
        const response = await fetch('https://base-fairlaunch-api-0df02e9f3f15.herokuapp.com/api/add', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'error',
          body: JSON.stringify(sendData)
        })

        if (response.status !== 200) {
          setLoading(false)
          const { error } = await response.json()
          throw new Error(error)

        }
        toast.success(`Updated`)
        setLoading(false)
        window.location.reload();
      }
    } catch (err) {
      setLoading(false)
      toast.error(
        'There is a problem with your update. Try again later'
      )
    }
  }

  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar"></div>
            <div className="headerMargin" />
            <section className="ProfileBox">
              <>
                <br />
                <div className="claim-card">
                  <div style={{ textAlign: 'left' }}>
                    <button
                      className="back-button"
                      onClick={() => history.goBack()}
                    >
                      <img src={BackIcon} className="back-icon" />
                      &nbsp;Back
                    </button>
                  </div>
                  <div className="profile-user-details-wrapper">
                    <div>
                      <p className="profile-text">
                        <b>Edit Profile</b>
                      </p>
                    </div>
                    <div>
                    </div>
                    <div>
                      <p className="profile-text">Profile photo</p>
                    </div>
                    <div>
                      <div className="profile-user-avatar">
                        <BannerImageUpload
                          onChange={handleImageBannerChange}
                          className="upload-box-profile-banner"
                          style={undefined}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="profile-text">Username</p>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userName}
                        className="profile-text-input"
                        onChange={e => setUserName(e.target.value)}
                      />
                    </div>
                    <div>
                      <p className="profile-text">Bio</p>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userBio}
                        className="profile-text-input"
                        onChange={e => setUserBio(e.target.value)}
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    {loading === false ?
                      <button className="save-button" onClick={onSave}>Save</button>
                      :
                      <>
                        <div className="loadingBox">
                          <p className="Text1" style={{ color: 'black' }}>
                            Loading...
                          </p>
                          <ClipLoader
                            color={'#afccc6'}
                            loading={loading}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    }

                  </div>
                </div>
                <br />
              </>
            </section>
          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default EditProfile
