/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { useAccount, useNetwork } from 'wagmi'
import { fetchBalance } from '@wagmi/core'
import '../App.css'
import TokenAbi from '../config/TokenAbi.json'
import PoolAbi from '../config/PoolAbi.json'
import FactoryAbi from '../config/FactoryAbi.json'
import '../styles/MainContainer.css'
import Input from '../components/Input.tsx'
import { readContract, writeContract } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { waitForTransaction } from '@wagmi/core'
import Web3 from 'web3'
import { useWeb3Modal } from '@web3modal/react'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer.jsx'
import TopBar from '../components/TopBar.jsx'
import LogoUploadBox from '../components/LogoUploadBox.jsx'
import BannerUploadBox from '../components/BannerUploadBox'
import TextArea from '../components/TextArea.tsx'
import MultiCallAbi from '../config/MultiCallAbi.json'
import { Address } from 'viem';

const App = () => {
    const BASE_PROVIDER_URL = 'https://base-pokt.nodies.app'
    let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
    const BaseTokenFactoryAddress = '0x06DbE8a166b50e0dDe6c580D15C6112e96AB3f04'
    const MultiCallAddress = '0x4Ec30E9D706c881b0B6D67929025C77bA473214D'
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork()
    const logoFileInput = useRef<HTMLInputElement>(null)
    const bannerFileInput = useRef<HTMLInputElement>(null)
    const [logoPreview, setLogoPreview] = useState<string | null>(null)
    const [logoFile, setLogoFile] = useState<File | null>(null)
    const [bannerPreview, setBannerPreview] = useState<string | null>(null)
    const [bannerFile, setBannerFile] = useState<File | null>(null)
    const [tokenName, setTokenName] = useState('')
    const [tokenSymbol, setTokenSymbol] = useState('')
    const [tokenDecimal, setTokenDecimal] = useState("18")
    const [tokenSupply, setTokenSupply] = useState("0")
    const [ethSupply, setETHSupply] = useState('0')
    const [tokenLpSupply, setTokenLpSupply] = useState('0')
    const [website, setWebsite] = useState('')
    const [telegram, setTelegram] = useState('')
    const [discord, setDiscord] = useState('')
    const [twitter, setTwitter] = useState('')
    let [accountBalance, setAccountBalance] = useState(0)
    let [accountTokenBalance, setAccountTokenBalance] = useState(0)
    const [selectedTokenLP, setSelectedTokenLP] = useState('')
    const [selectedBurnTokenLP, setSelectedBurnTokenLP] = useState('')
    let [lpcreating, setLpCreating] = useState(false)
    let [lpburning, setLpBurning] = useState(false)
    const [tokenDescription, setTokenDescription] = useState('')
    const [selectedToken, setSelectedToken] = useState('')
    let [creating, setCreating] = useState(false)
    let [revoking, setRevoking] = useState(false)
    let [revoked, setRevoked] = useState(false)
    let [lpCoinAddress, setLpCoinAddress] = useState('')
    const [firstConnect, setFirstConnect] = useState(false)
    const { open } = useWeb3Modal()
    const [coinLists, setCoinLists] = useState([
        { value: 'No Tokens', label: 'No Tokens' }
    ])

    const [lpcoinLists, setLpCoinLists] = useState([
        { value: 'No Tokens', label: 'No Tokens' }
    ])
    const [coinEnabled, setCoinEnabled] = useState(false)
    const [BurnLpCoinEnabled, setBurnLpCoinEnabled] = useState(false)
    const [whitelisted, setWhitelisted] = useState(false)

    const [firstBuyAmount, setFirstBuyAmount] = useState(0)

    const onConnectWallet = async () => {
        await open()
        setFirstConnect(true)
    }

    useEffect(() => {
        const FetchData = async () => {
            try {
                let whitelistedAddress = await readContract({
                    address: BaseTokenFactoryAddress,
                    abi: FactoryAbi,
                    functionName: 'whitelists',
                    args: [address]
                })
                if (whitelistedAddress === true)
                    setWhitelisted(true)
            } catch (e) {
                console.error(e)
            }
        }
        if (address) FetchData()
    }, [chain?.id, address])

    useEffect(() => {
        const FetchData = async () => {
            const coinOwner = await readContract({
                address: selectedTokenLP as Address,
                abi: TokenAbi,
                functionName: 'owner'
            })
            if (coinOwner === '0x0000000000000000000000000000000000000000') {
                setRevoked(true)
            } else {
                setRevoked(false)
            }
            const enabled = await readContract({
                address: selectedTokenLP as Address,
                abi: TokenAbi,
                functionName: 'renounced'
            })
            if (enabled === true) {
                setCoinEnabled(enabled)
            }
        }
        if (selectedTokenLP !== '' && selectedTokenLP !== 'No Tokens') {
            FetchData()
        }
    }, [selectedTokenLP])

    useEffect(() => {
        const FetchData = async () => {
            const token1 = await readContract({
                address: selectedBurnTokenLP as Address,
                abi: PoolAbi,
                functionName: 'token1'
            })
            let tokenAddress
            if (token1 !== '0x4200000000000000000000000000000000000006') {
                tokenAddress = token1
            } else {
                const token0 = await readContract({
                    address: selectedBurnTokenLP as Address,
                    abi: PoolAbi,
                    functionName: 'token0'
                })
                tokenAddress = token0
            }
            setLpCoinAddress(tokenAddress)
            const enabled = await readContract({
                address: tokenAddress,
                abi: TokenAbi,
                functionName: 'renounced'
            })
            if (enabled === true) {
                setBurnLpCoinEnabled(true)
            }
        }
        if (selectedBurnTokenLP !== '' && selectedBurnTokenLP !== 'No Tokens') {
            FetchData()
        }
    }, [selectedBurnTokenLP])

    const onTokenCreate = async () => {
        try {
            setCreating(true)
            let feeAmount = 0;
            if (whitelisted === true) {
                feeAmount = 0
            } else {
                feeAmount = 0.01
            }
            let create
            if (logoFile && bannerFile) {
                create = await writeContract({
                    address: BaseTokenFactoryAddress,
                    abi: FactoryAbi,
                    functionName: 'createCoin',
                    value: BaseWeb3.utils.toWei(String(feeAmount), 'ether'),
                    args: [
                        address,
                        [
                            tokenName,
                            tokenSymbol,
                            tokenDescription,
                            website,
                            telegram,
                            discord,
                            twitter
                        ],
                        tokenSupply,
                        tokenDecimal
                    ]
                })
                const createData = await waitForTransaction({
                    hash: create.hash
                })

                let tokenAddress
                let tokenAddresses = await readContract({
                    address: BaseTokenFactoryAddress,
                    abi: FactoryAbi,
                    functionName: 'allLists'
                })
                if (tokenAddresses)
                    tokenAddress = tokenAddresses[tokenAddresses.length - 1];

                let logoUrl
                let bannerUrl
                if (logoFile) {
                    const formData = new FormData()
                    formData.append('file', logoFile, tokenAddress)
                    fetch('https://moonbase.zapto.org:13001/uploads', {
                        method: 'POST',
                        body: formData
                    })
                        .then(async res => {
                            console.log('Success:', res)
                            logoUrl = await res.json()
                            logoUrl = logoUrl.fileInfo.filename
                            if (bannerFile) {
                                const formData = new FormData()
                                formData.append('file', bannerFile, tokenAddress)
                                fetch('https://moonbase.zapto.org:13001/uploads', {
                                    method: 'POST',
                                    body: formData
                                })
                                    .then(async res => {
                                        console.log('Success:', res)
                                        bannerUrl = await res.json()
                                        bannerUrl = bannerUrl.fileInfo.filename
                                        console.log('createData', createData)
                                        toast.success(`Successfully ${tokenName} token created`)
                                    })
                            }
                            setCreating(false)
                        })
                }
            }
        } catch (err) {
            toast.error(
                'There is a problem with your token create. Try again later'
            )
            setCreating(false)
        }
    }

    const onTokenRevoke = async () => {
        try {
            setRevoking(true)
            let revoke
            let feeAmount = 0.01;
            // if (whitelisted === true) {
            //     feeAmount = 0
            // } else {
            //     feeAmount = 0.01
            // }
            const coinOwner = await readContract({
                address: selectedToken as Address,
                abi: TokenAbi,
                functionName: 'owner'
            })
            if (coinOwner === '0x0000000000000000000000000000000000000000') {
                return toast.error(`Already renounced`)
            }
            revoke = await writeContract({
                address: selectedToken as Address,
                abi: TokenAbi,
                functionName: '_renounceOwnership',
                value: BaseWeb3.utils.toWei(String(feeAmount), 'ether')
            })
            const revokeData = await waitForTransaction({
                hash: revoke.hash
            })
            console.log('revokeData', revokeData)
            setTimeout(function () {
                setRevoking(false)
            }, 3000)
            setRevoked(true)
            toast.success(`Successfully renounced`)
        } catch (err) {
            toast.error(
                'There is a problem with your token revoke. Try again later'
            )
            setRevoking(false)
        }
    }

    const onLpCreating = async () => {
        try {
            let feeAmount = 0.01;
            // if (whitelisted === true) {
            //     feeAmount = 0
            // } else {
            //     feeAmount = 0.01
            // }
            setLpCreating(true)
            let ethAmount = Number(ethSupply) + feeAmount;
            let create;
            const tokenDecimal = await readContract({
                address: selectedTokenLP as Address,
                abi: TokenAbi,
                functionName: 'decimals'
            })
            const tokenAmounts = (Number(tokenLpSupply) * (10 ** Number(tokenDecimal))) / (10 ** 18);
            create = await writeContract({
                address: selectedTokenLP as Address,
                abi: TokenAbi,
                functionName: 'createLp',
                value: BaseWeb3.utils.toWei(String(ethAmount), 'ether'),
                args: [BaseWeb3.utils.toWei(String(tokenAmounts), 'ether'), BaseWeb3.utils.toWei(String(firstBuyAmount), 'ether')]
            })
            const createdData = await waitForTransaction({
                hash: create.hash
            })
            console.log('createdData', createdData)
            setTimeout(function () {
                setLpCreating(false)
            }, 3000)
            toast.success(`Successfully Created Lp`)
        } catch (err) {
            toast.error(
                'There is a problem with your LP creation. Try again later'
            )
            setLpCreating(false)
        }
    }

    const onLpBurn = async () => {
        try {
            let feeAmount = 0.01;
            // if (whitelisted === true) {
            //     feeAmount = 0
            // } else {
            //     feeAmount = 0.01
            // }
            setLpBurning(true)
            let balance = await readContract({
                address: selectedBurnTokenLP as Address,  // lp Address
                abi: TokenAbi,
                functionName: 'balanceOf',
                args: [address]
            })
            if (Number(balance) > 0) {
                let allowance = await readContract({
                    address: selectedBurnTokenLP as Address,
                    abi: PoolAbi,
                    functionName: 'allowance',
                    args: [address, lpCoinAddress]
                })
                if (Number(allowance) > 0) {
                    let burn
                    burn = await writeContract({
                        address: lpCoinAddress as Address,
                        abi: TokenAbi,
                        functionName: 'LpBurn',
                        value: BaseWeb3.utils.toWei(String(feeAmount), 'ether')
                    })
                    const createdData = await waitForTransaction({
                        hash: burn.hash
                    })
                    console.log('createdData', createdData)
                    setTimeout(function () {
                        setLpBurning(false)
                    }, 3000)
                    toast.success(`Successfully Burned Lp`)
                } else {
                    let approve
                    approve = await writeContract({
                        address: selectedBurnTokenLP,
                        abi: PoolAbi,
                        functionName: 'approve',
                        args: [lpCoinAddress as Address, balance]
                    })
                    const createdData = await waitForTransaction({
                        hash: approve.hash
                    })
                    console.log('createdData', createdData)
                    setTimeout(function () {
                        setLpBurning(false)
                    }, 3000)
                    toast.success(`Successfully approved Lp. Burn lp again`)
                }
            } else {
                toast.error('Create LP First')
                setLpBurning(false)
            }
        } catch (err) {
            toast.error(
                'There is a problem with your LP creation. Try again later'
            )
            setLpBurning(false)
        }
    }

    const addTokenToWallet = (tokenSymbol, tokenAddress) => async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

    const copyAddress = address => async () => {
        if (document.queryCommandSupported('copy')) {
            const ele = document.createElement('textarea')
            ele.value = address
            document.body.appendChild(ele)
            ele.select()
            document.execCommand('copy')
            document.body.removeChild(ele)
            displayTooltip()
        }
    }

    function displayTooltip() {
        let timeoutId
        setIsTooltipDisplayed(true)
        timeoutId = setTimeout(() => {
            setIsTooltipDisplayed(false)
        }, 1000)
        return () => clearTimeout(timeoutId)
    }

    useEffect(() => {
        const FetchData = async () => {
            try {
                let balance
                if (address)
                    balance = await fetchBalance({ address: address })
                if (balance)
                    setAccountBalance(balance.formatted)
                const coinListsLength = await readContract({
                    address: BaseTokenFactoryAddress,
                    abi: FactoryAbi,
                    functionName: 'coinListLength',
                    args: [address]
                })
                const length = Number(coinListsLength)
                let coinList = { value: '', label: '' }
                let LpCoinList
                if (length > 0) {
                    let coinLists = [{ value: '', label: '' }]
                    let LpCoinLists = [{ value: '', label: '' }]
                    for (let i = 0; i < length; i++) {
                        let coinInfo = await readContract({
                            address: MultiCallAddress,
                            abi: MultiCallAbi,
                            functionName: 'coinBasicInfo',
                            args: [address, i]
                        })
                        if (coinInfo) {
                            coinList = { value: coinInfo[0], label: coinInfo[1] }
                            coinLists.push(coinList)
                            if (coinInfo[2] !== '0x0000000000000000000000000000000000000000') {
                                LpCoinList = { label: coinInfo[2], value: coinInfo[2] }
                                LpCoinLists.push(LpCoinList)
                            }
                        }
                    }
                    setLpCoinLists(LpCoinLists)
                    setCoinLists(coinLists)
                }
            } catch (e) {
                console.error(e)
            }
        }
        if (creating === false && address !== undefined && lpburning === false) {
            FetchData()
        }
    }, [address, chain?.id, creating, lpburning])

    useEffect(() => {
        const FetchData = async () => {
            try {
                const tokenBalance = await readContract({
                    address: selectedTokenLP as Address,
                    abi: TokenAbi,
                    functionName: 'balanceOf',
                    args: [address]
                })
                const tokenDecimal = await readContract({
                    address: selectedTokenLP as Address,
                    abi: TokenAbi,
                    functionName: 'decimals'
                })
                setAccountTokenBalance(
                    Number(tokenBalance) / 10 ** Number(tokenDecimal)
                )
            } catch (e) {
                console.error(e)
            }
        }
        if (selectedTokenLP !== '' && selectedTokenLP !== 'No Tokens') {
            FetchData()
        }
    }, [selectedTokenLP])

    useEffect(() => {
        const FetchData = async () => {
            try {
                const tokenBalance = await readContract({
                    address: selectedBurnTokenLP as Address,
                    abi: TokenAbi,
                    functionName: 'balanceOf',
                    args: [address]
                })
                const tokenDecimal = await readContract({
                    address: selectedBurnTokenLP as Address,
                    abi: TokenAbi,
                    functionName: 'decimals'
                })
                setAccountTokenBalance(
                    Number(tokenBalance) / 10 ** Number(tokenDecimal)
                )
            } catch (e) {
                console.error(e)
            }
        }
        if (selectedBurnTokenLP !== '' && selectedBurnTokenLP !== 'No Tokens') {
            FetchData()
        }
    }, [selectedBurnTokenLP])

    const [faqVisibility, setFaqVisibility] = useState([false, false, false])

    const handleFAQClick = (e, index) => {
        const newVisibility = [...faqVisibility]
        newVisibility[index] = !newVisibility[index]
        newVisibility.forEach((item, i) => {
            if (i !== index) {
                newVisibility[i] = false
            }
        })
        setFaqVisibility(newVisibility)
    }

    function isMobile() {
        try {
            document.createEvent('TouchEvent')
            return true
        } catch (e) {
            return false
        }
    }

    const setMaxETHAmount = async () => {
        let feeAmount = 0;
        if (whitelisted === true) {
            feeAmount = 0
        } else {
            feeAmount = 0.01
        }
        accountBalance = accountBalance - 0.001 - feeAmount
        setETHSupply(accountBalance.toString())
    }

    const setTokenMaxAmount = async () => {
        setTokenLpSupply(accountTokenBalance.toString())
    }

    const [, setImageLogoFile] = useState(null)

    const handleImageLogoChange = file => {
        setImageLogoFile(file)
    }

    const [, setImageBannerFile] = useState(null)

    const handleImageBannerChange = file => {
        setImageBannerFile(file)
    }

    const LogoImageUpload = ({ onChange, className, style }) => {
        const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const selectedFile = e.target.files![0]
            setLogoFile(selectedFile)
            setLogoPreview(URL.createObjectURL(selectedFile))
            onChange(selectedFile)
        }
        const onButtonClick = () => {
            if (logoFileInput.current) {
                logoFileInput.current.click()
            }
        }
        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <input
                    type="file"
                    ref={logoFileInput}
                    accept="image/*"
                    onChange={handleLogoImageChange}
                    style={{ display: 'none' }}
                />
                <LogoUploadBox
                    imageUrl={logoPreview}
                    handleClick={onButtonClick}
                    className={className}
                    style={style}
                />
            </div>
        )
    }

    const BannerImageUpload = ({ onChange, className, style }) => {
        const handleBannerImageChange = (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            const selectedFile = e.target.files![0]
            setBannerFile(selectedFile)
            setBannerPreview(URL.createObjectURL(selectedFile))
            onChange(selectedFile)
        }
        const onButtonClick = () => {
            if (bannerFileInput.current) {
                bannerFileInput.current.click()
            }
        }
        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <input
                    type="file"
                    ref={bannerFileInput}
                    accept="image/*"
                    onChange={handleBannerImageChange}
                    style={{ display: 'none' }}
                />
                <BannerUploadBox
                    imageUrl={bannerPreview}
                    handleClick={onButtonClick}
                    className={className}
                    style={style}
                />
            </div>
        )
    }

    return (
        <div>
            <div className="GlobalContainer">
                {
                    <div style={{ zIndex: 1 }}>
                        <TopBar />
                        <div className="navBar">
                        </div>
                        <div className="headerMargin" />
                        <div className="MainDashboard ResponsiveFlexLayout">
                            <section className="ContactBox RightColumn">
                                <>
                                    <section>
                                        <section className="DepositBoxHeader" style={{ marginTop: "32px" }}>
                                            {/*<p className="ContractContentTextTitle h1">
                        Base Token Creator
                      </p>
                      <p
                        style={{
                          textAlign: 'left',
                          marginTop: '-12px',
                          paddingBottom: '16px'
                        }}
                      >
                        (Passes all DEXScreener & DEXTools security checks)
                      </p>*/}
                                        </section>
                                        <div className="MainContainer">
                                            <>
                                                <h2
                                                    className="ContractContentTextTitle h2"
                                                    style={{
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    Base Token Creator
                                                </h2>

                                                <section className="InputBox">
                                                    <section className="InputSection">
                                                        <div className="LpBalance">
                                                            <p className="Text1">Name</p>
                                                        </div>
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <Input
                                                                    placeholder="Enter Name"
                                                                    label=""
                                                                    type="text"
                                                                    changeValue={setTokenName}
                                                                    value={tokenName}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>

                                                    <section className="InputSection">
                                                        <div className="LpBalance">
                                                            <p className="Text1">Symbol</p>
                                                        </div>
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <Input
                                                                    placeholder="Enter Symbol"
                                                                    label=""
                                                                    type="text"
                                                                    changeValue={setTokenSymbol}
                                                                    value={tokenSymbol}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>
                                                </section>

                                                <section className="InputBox">
                                                    <section className="InputSection">
                                                        <div className="LpBalance">
                                                            <p className="Text1">Decimals</p>
                                                        </div>
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <Input
                                                                    placeholder="Enter Token Decimals"
                                                                    label=""
                                                                    type="number"
                                                                    changeValue={setTokenDecimal}
                                                                    value={tokenDecimal}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>

                                                    <section className="InputSection">
                                                        <div className="LpBalance">
                                                            <p className="Text1">Supply</p>
                                                        </div>
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <Input
                                                                    placeholder="Enter Token Supply"
                                                                    label=""
                                                                    type="number"
                                                                    changeValue={setTokenSupply}
                                                                    value={tokenSupply.toString()}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>
                                                </section>
                                                <section className="InputBox">
                                                    <section className="InputSection_Description">
                                                        <div>
                                                            <p className="subheader">
                                                                Additional Information for MoonBased listing.
                                                                (Optional)
                                                            </p>
                                                            <p className="subheader2">
                                                                Provide this information if you plan to launch
                                                                your token on MoonBased.
                                                            </p>
                                                        </div>{' '}
                                                        <div className="LpBalance">
                                                            <p className="Text1">Description</p>
                                                        </div>
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <TextArea
                                                                    rows={6}
                                                                    placeholder="Enter Token Description"
                                                                    changeValue={setTokenDescription}
                                                                    value={tokenDescription}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>
                                                </section>
                                                <section className="InputBox">
                                                    <section className="InputSection InputSectionLogo">
                                                        <div className="LpBalance">
                                                            <p className="Text1">Upload Logo</p>
                                                        </div>
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <LogoImageUpload
                                                                    onChange={handleImageLogoChange}
                                                                    className={undefined}
                                                                    style={undefined}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>

                                                    <section className="InputSection InputSectionBanner">
                                                        <div className="LpBalance">
                                                            <p className="Text1">Upload Banner</p>
                                                        </div>
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <BannerImageUpload
                                                                    onChange={handleImageBannerChange}
                                                                    className="upload-box-banner"
                                                                    style={undefined}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>
                                                </section>
                                                <>
                                                    <section className="InputBox">
                                                        <section className="InputSection_Description">
                                                            <div className="LpBalance">
                                                                <p className="Text1">Website</p>
                                                            </div>
                                                            <section className="inputPanel">
                                                                <section className="inputPanelHeader">
                                                                    <Input
                                                                        placeholder="https://"
                                                                        label=""
                                                                        type="text"
                                                                        changeValue={setWebsite}
                                                                        value={website}
                                                                    />
                                                                </section>
                                                            </section>
                                                        </section>
                                                    </section>

                                                    <section className="InputBox">
                                                        <section className="InputSection_Description">
                                                            <div className="LpBalance">
                                                                <p className="Text1">Telegram</p>
                                                            </div>
                                                            <section className="inputPanel">
                                                                <section className="inputPanelHeader">
                                                                    <Input
                                                                        placeholder="https://"
                                                                        label=""
                                                                        type="text"
                                                                        changeValue={setTelegram}
                                                                        value={telegram}
                                                                    />
                                                                </section>
                                                            </section>
                                                        </section>
                                                    </section>

                                                    <section className="InputBox">
                                                        <section className="InputSection_Description">
                                                            <div className="LpBalance">
                                                                <p className="Text1">Discord</p>
                                                            </div>
                                                            <section className="inputPanel">
                                                                <section className="inputPanelHeader">
                                                                    <Input
                                                                        placeholder="https://"
                                                                        label=""
                                                                        type="text"
                                                                        changeValue={setDiscord}
                                                                        value={discord}
                                                                    />
                                                                </section>
                                                            </section>
                                                        </section>
                                                    </section>

                                                    <section className="InputBox">
                                                        <section className="InputSection_Description">
                                                            <div className="LpBalance">
                                                                <p className="Text1">X.com</p>
                                                            </div>
                                                            <section className="inputPanel">
                                                                <section className="inputPanelHeader">
                                                                    <Input
                                                                        placeholder="https://"
                                                                        label=""
                                                                        type="text"
                                                                        changeValue={setTwitter}
                                                                        value={twitter}
                                                                    />
                                                                </section>
                                                            </section>
                                                        </section>
                                                    </section>
                                                </>
                                            </>
                                            {creating === false ? (
                                                isConnected ? (
                                                    <button
                                                        disabled={
                                                            tokenName === '' ||
                                                            tokenSymbol === '' ||
                                                            Number(tokenDecimal) === 0 ||
                                                            Number(tokenSupply) === 0 ||
                                                            tokenDescription === '' ||
                                                            logoFile === null ||
                                                            bannerFile === null ||
                                                            (Number(accountBalance) < 0.01 && whitelisted === false)
                                                        }
                                                        onClick={onTokenCreate}
                                                        className="CreateButton"
                                                    >
                                                        {tokenName === '' ||
                                                            tokenSymbol === '' ||
                                                            Number(tokenDecimal) === 0 ||
                                                            Number(tokenSupply) === 0 ||
                                                            tokenDescription === '' ||
                                                            logoFile === null ||
                                                            bannerFile === null
                                                            ? 'Enter Token Details'
                                                            :
                                                            Number(accountBalance) < 0.01 && whitelisted === false ?
                                                                'You need more ETH Now' : 'Create Token Now!'}
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="CreateButton"
                                                            type="submit"
                                                            onClick={() => {
                                                                onConnectWallet()
                                                            }}
                                                        >
                                                            Connect<span className="navWallet"> Wallet</span>
                                                        </button>
                                                    </>
                                                )
                                            ) : (
                                                <div className="loadingBox">
                                                    <p className="Text1">Creating...</p>
                                                    <ClipLoader
                                                        color={'#afccc6'}
                                                        loading={creating}
                                                        size={30}
                                                        aria-label="Loading Spinner"
                                                        data-testid="loader"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ marginBottom: '72px' }} />
                                        {coinLists[0].label !== 'No Tokens' ? (
                                            <>
                                                <div className="MainContainer">
                                                    <>
                                                        <section className="DepositBoxHeader">
                                                            <h2
                                                                className="ContractContentTextTitle h2"
                                                                style={{
                                                                    marginLeft: 'auto',
                                                                    marginRight: 'auto',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                Created Token Addresses
                                                            </h2>
                                                            {coinLists.map(({ value, label }, i) => (
                                                                <div>
                                                                    {isMobile() ? (
                                                                        <>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    marginLeft: 'auto',
                                                                                    marginRight: 'auto'
                                                                                }}
                                                                                className="tokenLists"
                                                                            >
                                                                                {label}
                                                                            </p>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    <p
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto'
                                                                        }}
                                                                        className="tokenLists"
                                                                    >
                                                                        {value}
                                                                    </p>
                                                                    <div className="tooltip">
                                                                        <button
                                                                            onClick={
                                                                                isMobile()
                                                                                    ? copyAddress(value)
                                                                                    : addTokenToWallet(label, value)
                                                                            }
                                                                            // onClick={copyAddress(value)}
                                                                            className="CreateButton"
                                                                        >
                                                                            {isMobile()
                                                                                ? 'Copy Token Address'
                                                                                : 'Add Token To Wallet'}
                                                                        </button>
                                                                        {isTooltipDisplayed && (
                                                                            <span className="tooltiptext">
                                                                                Copied!
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </section>
                                                    </>
                                                </div>
                                                <div style={{ marginBottom: '72px' }} />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="MainContainer">
                                            <>
                                                <section className="DepositBoxHeader">
                                                    <h2
                                                        className="ContractContentTextTitle h2 center"
                                                        style={{
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto'
                                                        }}
                                                    >
                                                        Renounce Contract Ownership
                                                    </h2>
                                                    <p
                                                        style={{
                                                            width: '90%',
                                                            textAlign: 'center',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            color: 'black'
                                                        }}
                                                    >
                                                        This is to provide additional security to investors.
                                                    </p>
                                                </section>
                                                <section className="">
                                                    <section className="InputSection_Description">
                                                        <section className="inputPanel">
                                                            <section className="inputPanelHeader">
                                                                <Select
                                                                    value={
                                                                        selectedToken
                                                                            ? {
                                                                                value: selectedToken,
                                                                                label: selectedToken
                                                                            }
                                                                            : null
                                                                    }
                                                                    onChange={option =>
                                                                        option && setSelectedToken(option.value)
                                                                    }
                                                                    options={coinLists}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    placeholder="Select Token"
                                                                    valueRenderer={({ value }) => value.label}
                                                                    className="RevokeTokenSelect"
                                                                    styles={{
                                                                        control: styles => ({
                                                                            ...styles,
                                                                            backgroundColor: '#ffffff',
                                                                            color: 'black'
                                                                        }),
                                                                        option: styles => ({
                                                                            ...styles,
                                                                            backgroundColor: '#aaaaaa',
                                                                            color: 'black'
                                                                        }),
                                                                        singleValue: styles => ({
                                                                            ...styles,
                                                                            color: 'black'
                                                                        }),
                                                                        input: styles => ({
                                                                            ...styles,
                                                                            caretColor: 'transparent'
                                                                        }),
                                                                        dropdownIndicator: styles => ({
                                                                            ...styles,
                                                                            color: 'black'
                                                                        }),
                                                                        placeholder: styles => ({
                                                                            gridArea: '1 / 1 / 2 / 3',
                                                                            color: 'rgb(71, 69, 69)',
                                                                            marginLeft: '2px',
                                                                            marginRight: '2px',
                                                                            boxSizing: 'border-box'
                                                                        })
                                                                    }}
                                                                />
                                                            </section>
                                                        </section>
                                                    </section>
                                                </section>
                                            </>
                                            {revoking === false ? (
                                                isConnected ? (
                                                    <button
                                                        disabled={
                                                            selectedToken === 'No Tokens' ||
                                                            selectedToken === '' ||
                                                            revoked === true
                                                        }
                                                        onClick={onTokenRevoke}
                                                        className="CreateButton"
                                                    >
                                                        {selectedToken === ''
                                                            ? 'Select Token'
                                                            : revoked === true
                                                                ? 'Successfully Renounced'
                                                                : 'Renounce Ownership!'}
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="CreateButton"
                                                            type="submit"
                                                            onClick={() => {
                                                                onConnectWallet()
                                                            }}
                                                        >
                                                            Connect<span className="navWallet"> Wallet</span>
                                                        </button>
                                                    </>
                                                )
                                            ) : (
                                                <div className="loadingBox">
                                                    <p className="Text1">Token Revoking ...</p>
                                                    <ClipLoader
                                                        color={'#afccc6'}
                                                        loading={revoking}
                                                        size={30}
                                                        aria-label="Loading Spinner"
                                                        data-testid="loader"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ marginBottom: '72px' }} />
                                        <div className="MainContainer">
                                            <>
                                                <section className="DepositBoxHeader">
                                                    <h2 className="ContractContentTextTitle h2 center">
                                                        Create LP Pool
                                                    </h2>
                                                </section>
                                                <section className="InputSection_LP">
                                                    <div className="LpBalance">
                                                        <p className="Text2">ETH</p>
                                                    </div>
                                                    <section className="inputPanel_lp">
                                                        <section className="inputPanelHeader relative">
                                                            <Input
                                                                placeholder="Enter ETH Supply"
                                                                label=""
                                                                type="number"
                                                                changeValue={setETHSupply}
                                                                value={ethSupply}
                                                            />
                                                        </section>
                                                    </section>
                                                    <div className="LpBalance">
                                                        <p
                                                            className="Text2 MaxButton MaxButtonETH"
                                                            onClick={setMaxETHAmount}
                                                        >
                                                            Max
                                                        </p>
                                                    </div>
                                                    <br />
                                                    <section className="InputSection_LP">
                                                        <div className="LpBalance">
                                                            <p className="Text2">Token</p>
                                                        </div>
                                                        <section className="inputPanel_lp">
                                                            <section className="inputPanelHeader">
                                                                <div className="LpInputBox">
                                                                    <Input
                                                                        placeholder="Enter Token Supply"
                                                                        label=""
                                                                        type="number"
                                                                        changeValue={setTokenLpSupply}
                                                                        value={tokenLpSupply}
                                                                    />
                                                                </div>
                                                                <Select
                                                                    className="LpTokenSelect"
                                                                    value={
                                                                        selectedTokenLP
                                                                            ? {
                                                                                value: selectedTokenLP,
                                                                                label: selectedTokenLP
                                                                            }
                                                                            : null
                                                                    }
                                                                    onChange={option =>
                                                                        option && setSelectedTokenLP(option.value)
                                                                    }
                                                                    options={coinLists}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    placeholder="Select&nbsp;Token"
                                                                    valueRenderer={({ value }) => value.label}
                                                                    styles={{
                                                                        control: styles => ({
                                                                            ...styles,
                                                                            backgroundColor: '#5272f7',
                                                                            color: 'white'
                                                                        }),
                                                                        option: styles => ({
                                                                            ...styles,
                                                                            backgroundColor: '#9aa8f7',
                                                                            color: 'white'
                                                                        }),
                                                                        singleValue: styles => ({
                                                                            ...styles,
                                                                            color: 'white'
                                                                        }),
                                                                        input: styles => ({
                                                                            ...styles,
                                                                            caretColor: 'transparent'
                                                                        }),
                                                                        dropdownIndicator: styles => ({
                                                                            ...styles,
                                                                            color: 'white'
                                                                        }),
                                                                        placeholder: styles => ({
                                                                            gridArea: '1 / 1 / 2 / 3',
                                                                            color: '#ffffff',
                                                                            marginLeft: '2px',
                                                                            fontWeight: 600,
                                                                            marginRight: '2px',
                                                                            boxSizing: 'border-box'
                                                                        })
                                                                    }}
                                                                />
                                                            </section>
                                                        </section>
                                                        <div className="LpBalance">
                                                            <p
                                                                className="Text2 MaxButton MaxButtonToken"
                                                                onClick={setTokenMaxAmount}
                                                            >
                                                                Max
                                                            </p>
                                                        </div>
                                                    </section>
                                                    <br />
                                                    <div className="LpBalance">
                                                        <p className="Text2">Initial buy (optional)</p>
                                                    </div>
                                                    <section className="inputPanel_lp">
                                                        <section className="inputPanelHeader relative">
                                                            <Input
                                                                placeholder="Enter ETH Amount"
                                                                label=""
                                                                type="number"
                                                                changeValue={setFirstBuyAmount}
                                                                value={firstBuyAmount}
                                                            />
                                                        </section>
                                                    </section>
                                                </section>
                                            </>
                                            {lpcreating === false ? (
                                                isConnected ? (
                                                    <button
                                                        disabled={
                                                            selectedTokenLP === 'No Tokens' ||
                                                            selectedTokenLP === ''
                                                        }
                                                        onClick={onLpCreating}
                                                        className="CreateButton"
                                                    >
                                                        {selectedTokenLP === ''
                                                            ? 'Select Token'
                                                            : coinEnabled === true
                                                                ? 'Start Now!'
                                                                : 'Renounce ownership first'}
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="CreateButton"
                                                            type="submit"
                                                            onClick={() => {
                                                                onConnectWallet()
                                                            }}
                                                        >
                                                            Connect<span className="navWallet"> Wallet</span>
                                                        </button>
                                                    </>
                                                )
                                            ) : (
                                                <div className="loadingBox">
                                                    <p className="Text1">LP creating ...</p>
                                                    <ClipLoader
                                                        color={'#afccc6'}
                                                        loading={lpcreating}
                                                        size={30}
                                                        aria-label="Loading Spinner"
                                                        data-testid="loader"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ marginBottom: '72px' }} />
                                        {lpcoinLists.length > 0 ? (
                                            lpcoinLists[0]?.label !== 'No Tokens' ? (
                                                <>
                                                    <div className="MainContainer">
                                                        <>
                                                            <section className="DepositBoxHeader">
                                                                <h2
                                                                    className="ContractContentTextTitle h2 center"
                                                                >
                                                                    Created LP Token Addresses
                                                                </h2>
                                                                {lpcoinLists.map(({ value, label }, i) => (
                                                                    <div>
                                                                        {isMobile() ? (
                                                                            <>
                                                                                <p
                                                                                    style={{
                                                                                        textAlign: 'center',
                                                                                        marginLeft: 'auto',
                                                                                        marginRight: 'auto'
                                                                                    }}
                                                                                    className="tokenLists"
                                                                                >
                                                                                    {label}
                                                                                </p>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        <p
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                marginLeft: 'auto',
                                                                                marginRight: 'auto'
                                                                            }}
                                                                            className="tokenLists"
                                                                        >
                                                                            {value}
                                                                        </p>
                                                                        <div className="tooltip">
                                                                            <button
                                                                                onClick={
                                                                                    isMobile()
                                                                                        ? copyAddress(value)
                                                                                        : addTokenToWallet('UNI-V2', value)
                                                                                }
                                                                                className="CreateButton"
                                                                            >
                                                                                {isMobile()
                                                                                    ? 'Copy Token Address'
                                                                                    : 'Add Token To Wallet'}
                                                                            </button>
                                                                            {isTooltipDisplayed && (
                                                                                <span className="tooltiptext">
                                                                                    Copied!
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </section>
                                                        </>
                                                    </div>
                                                    <div style={{ marginBottom: '72px' }} />
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        ) : (
                                            <></>
                                        )}
                                        <div style={{ marginBottom: '72px' }} />
                                        {/* {lpcoinLists.length > 0 ? (
                      lpcoinLists[0].label !== 'No Tokens' ? ( */}
                                        <div className="MainContainer">
                                            <>
                                                <section className="DepositBoxHeader">
                                                    <h2 className="ContractContentTextTitle h2 center">
                                                        Burn LP Tokens
                                                    </h2>
                                                </section>
                                                <section className="InputSection_LP">
                                                    <section className="inputPanel_lp">
                                                        <section className="inputPanelHeader">
                                                            <Select
                                                                className="BurnLpTokenSelect"
                                                                value={
                                                                    selectedBurnTokenLP
                                                                        ? {
                                                                            value: selectedBurnTokenLP,
                                                                            label: selectedBurnTokenLP
                                                                        }
                                                                        : null
                                                                }
                                                                onChange={option =>
                                                                    option &&
                                                                    setSelectedBurnTokenLP(option.value)
                                                                }
                                                                options={lpcoinLists}
                                                                isClearable={false}
                                                                isSearchable={true}
                                                                placeholder="Select&nbsp;Token"
                                                                valueRenderer={({ value }) => value.label}
                                                                styles={{
                                                                    control: styles => ({
                                                                        ...styles,
                                                                        backgroundColor: '#ffffff',
                                                                        color: 'black'
                                                                    }),
                                                                    option: styles => ({
                                                                        ...styles,
                                                                        backgroundColor: '#9aa8f7',
                                                                        color: 'black'
                                                                    }),
                                                                    singleValue: styles => ({
                                                                        ...styles,
                                                                        color: 'black'
                                                                    }),
                                                                    input: styles => ({
                                                                        ...styles,
                                                                        caretColor: 'transparent'
                                                                    }),
                                                                    dropdownIndicator: styles => ({
                                                                        ...styles,
                                                                        color: 'black'
                                                                    }),
                                                                    placeholder: styles => ({
                                                                        gridArea: '1 / 1 / 2 / 3',
                                                                        color: '#rgb(71, 69, 69)',
                                                                        marginLeft: '2px',
                                                                        marginRight: '2px',
                                                                        boxSizing: 'border-box'
                                                                    })
                                                                }}
                                                            />
                                                        </section>
                                                    </section>
                                                </section>
                                            </>
                                            {lpburning === false ? (
                                                isConnected ? (
                                                    <button
                                                        disabled={
                                                            selectedBurnTokenLP === 'No Tokens' ||
                                                            selectedBurnTokenLP === ''
                                                        }
                                                        onClick={onLpBurn}
                                                        className="CreateButton"
                                                    >
                                                        {selectedBurnTokenLP === ''
                                                            ? 'Select Token'
                                                            : BurnLpCoinEnabled === true
                                                                ? 'Burn Now!'
                                                                : 'Renounce ownership first'}
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="CreateButton"
                                                            type="submit"
                                                            onClick={() => {
                                                                onConnectWallet()
                                                            }}
                                                        >
                                                            Connect
                                                            <span className="navWallet"> Wallet</span>
                                                        </button>
                                                    </>
                                                )
                                            ) : (
                                                <div className="loadingBox">
                                                    <p className="Text1">LP Burning ...</p>
                                                    <ClipLoader
                                                        color={'#afccc6'}
                                                        loading={lpburning}
                                                        size={30}
                                                        aria-label="Loading Spinner"
                                                        data-testid="loader"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {/* ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )} */}
                                    </section>
                                </>
                            </section>
                            <section className="LeftColumn">
                                <h1 className="ContractContentTextTitle h1">
                                    How to create Token
                                </h1>
                                <p className="text">
                                    Follow this guide to effortlessly create your very own Token
                                    on the BASE Network. (Passes all DEXScreener & DEXTools
                                    security checks)
                                </p>
                                <p>
                                    <ol>
                                        <li>Connect your Wallet.</li>
                                        <li>Specify the Name of your new Token.</li>
                                        <li>
                                            Enter the Token Symbol (Ticker) Maximum of 8 characters.
                                        </li>
                                        <li>Select the decimal quantity for the Token</li>
                                        <li>Provide a description of your Token</li>
                                        <li>
                                            Select the Total Supply for your new token (how many
                                            tokens will be created)
                                        </li>
                                        <li>
                                            Select create and accept the transaction in your wallet.
                                        </li>
                                        <li>Your new Token will now be sent to your wallet.</li>
                                    </ol>
                                </p>
                                <p>
                                    The cost for creating a Token is 0.01 ETH on the BASE network.
                                </p>
                                <br />
                                <h3>Renouncing contract ownership</h3>
                                <p>
                                    Renounce contract ownership to provide additional security and
                                    peace of mind to investors. (This is standard practice with
                                    meme and culture tokens)
                                </p>
                                <p>
                                    <ol>
                                        <li>
                                            Select the created Token you wish to renounce ownership
                                            from the drop down menu.
                                        </li>
                                        <li>Accept and submit transaction.</li>
                                        <li>MoonBased will renounce the ownership the contract.</li>
                                    </ol>
                                </p>
                                <p>
                                    The cost for renouncing ownership is 0.01 ETH on the BASE
                                    network.
                                </p>
                                <br />
                                <h3>Create LP Pool</h3>
                                <p>
                                    Before you can Trade your new Token with potential investors
                                    you need to create a Liquidity Pool (LP).
                                </p>
                                <p>
                                    All LP pools created on Moonbase are located on Uniswap V2.
                                    Uniswap is the main DEX or Decentralised Exchange for the BASE
                                    network providing security for project and investors alike.
                                </p>
                                <p>
                                    <ol>
                                        <li>
                                            Enter the amount of ETH that will be add into the pool.
                                        </li>
                                        <li>
                                            Select the Token you would like to create the LP pool with
                                            from the drop down menu.
                                        </li>
                                        <li>
                                            Chose the amount of Tokens you would like to add to LP.
                                        </li>
                                        <li>
                                            Submit transaction and your LP pool will be created.
                                        </li>
                                    </ol>
                                </p>
                                <p>
                                    The cost for creating an LP pool is 0.01 ETH on the BASE
                                    network.
                                </p>
                                <br />
                                <h3>Burn LP</h3>
                                <p>
                                    Moonbase offers the automated burning of the generated Uniswap V2 LP Tokens.
                                </p>
                                <p>
                                    By burning the LP Tokens, you provide additional security to potential investors as the Token will be impossible to “Rug-Pull” the common term used for a popular scam when Token Creators withdraw all the generated Liquidity in a project.
                                </p>

                                <p>
                                    <ol>
                                        <li>
                                            Select the created LP you wish to burn from the drop down menu.
                                        </li>
                                        <li>
                                            Accept and submit transaction.
                                        </li>
                                        <li>
                                            MoonBased will automatically burn the LP
                                        </li>
                                    </ol>
                                </p>
                                <p>
                                    Any Token launched on Moonbase using our LP burn will gain access to our “rug-proof” Ranking, to help attract new investors with the increased security offered.
                                </p>
                                <p>The cost for LP burn is 0.01 ETH on the BASE network.</p>
                            </section>
                        </div>
                    </div>
                }
                <section className="FAQ">
                    <div className="textContainer">
                        <h4>Frequently Asked Questions</h4>
                        <div className="faqItems">
                            <div className="faqItem">
                                <div
                                    className="faqTitle"
                                    onClick={e => handleFAQClick(e, 0)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span style={{ flex: 1 }}>
                                        <p>What is the Base Token Creator?</p>
                                    </span>
                                    <img
                                        src="/img/chevron-down-svgrepo-com.svg"
                                        width="16"
                                        height="16"
                                        className={`${faqVisibility[0] ? '' : 'rotate'}`}
                                        alt="Chevron down icon"
                                    />
                                </div>
                                <div
                                    className="faqAnswer"
                                    style={{ display: faqVisibility[0] ? 'block' : 'none' }}
                                >
                                    <p>
                                        The Base Token Creator is a user-friendly platform that
                                        allows you to create your own ERC20 token on the BASE
                                        network without any coding required.
                                    </p>
                                </div>
                                <hr />
                            </div>
                            <div className="faqItem">
                                <div
                                    className="faqTitle"
                                    onClick={e => handleFAQClick(e, 1)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span style={{ flex: 1 }}>
                                        <p>
                                            Is it safe to create Tokens using{' '}
                                            <span className="moonbase">MOONBASE</span>?
                                        </p>
                                    </span>
                                    <img
                                        src="/img/chevron-down-svgrepo-com.svg"
                                        width="16"
                                        height="16"
                                        className={`${faqVisibility[1] ? '' : 'rotate'}`}
                                        alt="Chevron down icon"
                                    />
                                </div>
                                <div
                                    className="faqAnswer"
                                    style={{ display: faqVisibility[1] ? 'block' : 'none' }}
                                >
                                    <p>
                                        Yes, it is safe to create tokens using{' '}
                                        <span className="moonbase">MOONBASE</span>. Our platform
                                        prioritizes the security and privacy of our users.
                                    </p>
                                </div>
                                <hr />
                            </div>
                            <div className="faqItem">
                                <div
                                    className="faqTitle"
                                    onClick={e => handleFAQClick(e, 2)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span style={{ flex: 1 }}>
                                        <p>How much time will it take to make my Token?</p>
                                    </span>
                                    <img
                                        src="/img/chevron-down-svgrepo-com.svg"
                                        width="16"
                                        height="16"
                                        className={`${faqVisibility[2] ? '' : 'rotate'}`}
                                        alt="Chevron down icon"
                                    />
                                </div>
                                <div
                                    className="faqAnswer"
                                    style={{ display: faqVisibility[2] ? 'block' : 'none' }}
                                >
                                    <p>
                                        Creating a token with{' '}
                                        <span className="moonbase">MOONBASE</span> is a quick and
                                        efficient process, taking only moments to complete. You'll
                                        have your new token and be ready to add liquidity and start
                                        trading in no time!
                                    </p>
                                </div>
                                <hr />
                            </div>
                            <div className="faqItem">
                                <div
                                    className="faqTitle"
                                    onClick={e => handleFAQClick(e, 3)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span style={{ flex: 1 }}>
                                        <p>How much does it cost to make a Token?</p>
                                    </span>
                                    <img
                                        src="/img/chevron-down-svgrepo-com.svg"
                                        width="16"
                                        height="16"
                                        className={`${faqVisibility[3] ? '' : 'rotate'}`}
                                        alt="Chevron down icon"
                                    />
                                </div>
                                <div
                                    className="faqAnswer"
                                    style={{ display: faqVisibility[3] ? 'block' : 'none' }}
                                >
                                    <p>
                                        The cost of creating a token with{' '}
                                        <span className="moonbase">MOONBASE</span> is 0.01 ETH on
                                        the BASE network, which covers all fees for ERC20 token
                                        creation.
                                    </p>
                                </div>
                                <hr />
                            </div>
                            <div className="faqItem">
                                <div
                                    className="faqTitle"
                                    onClick={e => handleFAQClick(e, 4)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span style={{ flex: 1 }}>
                                        <p>What wallets can I use?</p>
                                    </span>
                                    <img
                                        src="/img/chevron-down-svgrepo-com.svg"
                                        width="16"
                                        height="16"
                                        className={`${faqVisibility[4] ? '' : 'rotate'}`}
                                        alt="Chevron down icon"
                                    />
                                </div>
                                <div
                                    className="faqAnswer"
                                    style={{ display: faqVisibility[4] ? 'block' : 'none' }}
                                >
                                    <p>
                                        You can use any wallet that supports the BASE network to
                                        create and manage your tokens with{' '}
                                        <span className="moonbase">MOONBASE</span>. We recommend to
                                        use the Coinbase Wallet or Metamask.
                                    </p>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="FAQ">
                    <div className="textContainer">
                        <h4>BASE ERC20 Token Creator</h4>
                        <p>
                            If you're looking for a simple and efficient way to mint tokens on
                            the BASE network, our <span className="moonbase">MOONBASE</span>{' '}
                            token generator is the perfect solution. Our platform is designed
                            with ease of use in mind, allowing users to create and deploy
                            their tokens in just a few easy steps.
                        </p>
                        <p>
                            With our <span className="moonbase">MOONBASE</span> token
                            generator, you don't need to be a blockchain expert. Anyone can
                            quickly create and manage their tokens without any hassle. We also
                            prioritize the security and privacy of our users. All token
                            transactions and information are safeguarded by our secure
                            on-chain smart contract, ensuring the safety of your assets
                            throughout the entire process.
                        </p>
                        <p>
                            Our aim is to offer users a smooth and streamlined experience when
                            crafting tokens on the BASE network. With our online generator,
                            you can customize your tokens with unique descriptions and
                            issuance details, giving them a personalized touch that reflects
                            your brand or project.
                        </p>
                        <br />
                        <h4>Why create Tokens using MoonBased?</h4>
                        <p>
                            Whether you're a seasoned coder or new to the scene, our{' '}
                            <span className="moonbase">MOONBASE</span>
                            Token Builder software is crafted with you in mind. Discover the
                            simplicity of swiftly and safely minting tokens, preserving your
                            precious time and resources. What distinguishes us is our
                            steadfast dedication to outstanding assistance.
                        </p>
                        <p>
                            Our devoted team of specialists stands ready 24/7 to tackle any
                            questions or obstacles you may face. Embark on your venture of
                            crafting and overseeing BASE tokens with assurance today, knowing
                            that our dependable and protected online builder delivers an
                            unmatched journey. You won't encounter a more intuitive and
                            effective solution elsewhere!
                        </p>
                    </div>
                    <br />
                    <br />
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default App
