/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'

import Cookies from 'universal-cookie';

import { useAccount, useNetwork } from 'wagmi'
import '../App.css'
import FairLaunchAbi from '../config/FairLaunchAbi.json'
import MultiCallAbi from '../config/MultiCallAbi.json'
import routerAbi from '../config/UniswapRouterAbi.json'
import '../styles/MainContainer.css'
import Input from '../components/Input.tsx'
import { readContract, writeContract } from '@wagmi/core'
import { waitForTransaction } from '@wagmi/core'
import Web3 from 'web3'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer'
import ClaimCard from '../components/ClaimCard.jsx'
import { ReactComponent as StatusSaleLive } from '../icons/status-sale-live.svg'
import { ReactComponent as StatusSaleClosed } from '../icons/status-closed.svg'
import { ReactComponent as StatusUpcoming } from '../icons/status-upcoming.svg'
import BigCountdown from '../components/BigCountdown'
import TopBar from '../components/TopBar'
import ClipLoader from 'react-spinners/ClipLoader'
import { useQueryParam, StringParam } from 'use-query-params'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'
import MaxInput from '../components/MaxInput.tsx'
import ETHIcon from '../icons/eth.png'
import TokenAbi from '../config/TokenAbi.json'
import rot13 from '../../utils/encode.ts'
import { sortBy } from 'lodash'
import CopyIcon from '../icons/copy.svg'

const publicClient = createPublicClient({
  chain: base,
  transport: http()
})

const App = () => {
  let [addressDatas] = useQueryParam('address', StringParam)
  let presaleAddress
  let ref
  if (addressDatas.includes('/?ref=')) {
    presaleAddress = addressDatas.split('/?ref=')[0]
    ref = addressDatas.split('/?ref=')[1]
  } else {
    presaleAddress = addressDatas
  }
  const BASE_PROVIDER_URL = 'https://base-pokt.nodies.app'
  let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
  let WETHAddress = '0x4200000000000000000000000000000000000006';
  let routerAddress = '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24';
  const multicallAddress = '0x4Ec30E9D706c881b0B6D67929025C77bA473214D'
  let { address } = useAccount()
  const { chain } = useNetwork()
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [tokenLogo, setTokenLogo] = useState('')
  const [tokenBanner, setTokenBanner] = useState('')
  const [softCap, setSoftCap] = useState('')
  const [tokenSupply, setTokenSupply] = useState(100)
  const [liquidity, setLiquidiity] = useState(0)
  const [startTime, setStartTime] = useState(new Date().toLocaleString())
  const [endTime, setEndTime] = useState(new Date().toLocaleString())

  const [startCounterTime, setStartCounterTime] = useState(new Date().toLocaleString())
  const [endCounterTime, setEndCounterTime] = useState(new Date().toLocaleString())

  const [depositedAmount, setDepositedAmount] = useState(0)
  const [maxBuyAmount, setMaxBuyAmount] = useState(0)
  const [tokenDescription, setTokenDescription] = useState('')
  const [saleLive, setSaleLive] = useState('')
  const [allIns, setAllIn] = useState('')
  const [state, setState] = useState()
  const [tokenAmount, setAmount] = useState(0)
  let [accountBalance, setAccountBalance] = useState(0)
  const [depositUserAmount, setDepositUserAmount] = useState()
  const [investors, setInvestors] = useState()
  const [refundable, setRefundable] = useState()
  const [claimable, setClaimable] = useState()
  const [owner, setOwner] = useState()
  let [creating, setCreating] = useState(false)

  const [website, setWebsite] = useState()
  const [twitter, setTwitter] = useState()
  const [telegram, setTelegram] = useState()
  const [discord, setDiscord] = useState()
  const [claimableAmount, setClaimableAmount] = useState()
  const [withdrawAmount, setWithdrawAmount] = useState()

  let [inputBalance, setInputBalance] = useState(0)
  let [tokenBalance, setTokenBalance] = useState(0)
  let [tokenAllowance, setTokenAllowance] = useState(0)
  const [tokenOutAmount, setTokenOutAmount] = useState(0)

  let [tab, setTab] = useState(0);
  const [ownerName, setOwnerName] = useState('')
  const [bannerPreview, setBannerPreview] = useState('')

  const [refPerc, setRefPerc] = useState(0)
  const [refCounts, setRefCounts] = useState(0)
  const [totalRefAmounts, setTotalRefAmounts] = useState(0)
  const [refDatas, setRefDatas] = useState([])

  let refPresale = 'ref' + presaleAddress
  const cookies = new Cookies();
  if (ref) {
    if (Web3.utils.isAddress(rot13(ref))) {
      cookies.set(refPresale, ref)
    }
  }
  let refAddress
  if (cookies.get(refPresale)) {
    if (Web3.utils.isAddress(rot13(cookies.get(refPresale)))) {
      refAddress = rot13(cookies.get(refPresale))
    }
  } else {
    refAddress = "0x0000000000000000000000000000000000000000"
  }
  const BASE_URL = 'https://moonbased.io/buy/?address=' + presaleAddress
  const referlink = address ? `${BASE_URL}/?ref=${rot13(address)}` : `${BASE_URL}/?ref=`
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  const copyAddress = address => async e => {
    e.stopPropagation()
    e.preventDefault()
    if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  function displayTooltip() {
    let timeoutId
    setIsTooltipDisplayed(true)
    timeoutId = setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }
  const onTokenDeposit = async () => {
    try {
      setCreating(true)
      let deposit
      deposit = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'contributeETH',
        value: BaseWeb3.utils.toWei(String(tokenAmount), 'ether'),
        args: [refAddress]
      })
      const depositData = await waitForTransaction({
        hash: deposit.hash
      })
      console.log('depositData', depositData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully ${tokenAmount} ETH deposited`)
    } catch (err) {
      toast.error(
        'There is a problem with your ETH deposit. Try again later'
      )
      setCreating(false)
    }
  }

  const onWithdraw = async () => {
    try {
      setCreating(true)
      let withdraw
      withdraw = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'withdrawContribute'
      })
      const withdrawData = await waitForTransaction({
        hash: withdraw.hash
      })
      console.log('withdrawData', withdrawData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully withdrawed`)
    } catch (err) {
      toast.error(
        'There is a problem with your ETH withdraw. Try again later'
      )
      setCreating(false)
    }
  }

  const onTokenEmergency = async () => {
    try {
      setCreating(true)
      let withdraw
      withdraw = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'emergencyWithdraw'
      })
      const withdrawData = await waitForTransaction({
        hash: withdraw.hash
      })
      console.log('withdrawData', withdrawData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully withdrawed`)
    } catch (err) {
      toast.error(
        'There is a problem with your ETH withdraw. Try again later'
      )
      setCreating(false)
    }
  }

  const onClaim = async () => {
    try {
      setCreating(true)
      let claim
      claim = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'claim'
      })
      const claimData = await waitForTransaction({
        hash: claim.hash
      })
      console.log('claimData', claimData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully Claimed`)
    } catch (err) {
      toast.error('There is a problem with your claim. Try again later')
      setCreating(false)
    }
  }

  const onCancel = async () => {
    try {
      setCreating(true)
      let cancel
      cancel = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'cancel'
      })
      const cancelData = await waitForTransaction({
        hash: cancel.hash
      })
      console.log('cancelData', cancelData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully Cancelled`)
    } catch (err) {
      toast.error(
        'There is a problem with your setting. Try again later'
      )
      setCreating(false)
    }
  }

  const onFinalize = async () => {
    try {
      setCreating(true)
      let finalize
      finalize = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'finalize'
      })
      const finalizeData = await waitForTransaction({
        hash: finalize.hash
      })
      console.log('finalizeData', finalizeData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully Finalized`)
    } catch (err) {
      toast.error(
        'There is a problem with your setting. Try again later'
      )
      setCreating(false)
    }
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        const PresaleInfo = await publicClient.readContract({
          address: presaleAddress,
          abi: FairLaunchAbi,
          functionName: 'getPresaleInfo'
        })
        let state = 0
        if (PresaleInfo[0] === true) {
          state = 1
        } else {
          const presaleEndTimestamp = Number(PresaleInfo[1])
          if (Number(presaleEndTimestamp) < new Date().getTime() / 1000) {
            state = 2
          } else {
            state = 0
          }
        }
        setState(state)
        setTokenName(PresaleInfo[6])
        setTokenSymbol(PresaleInfo[8])
        setTokenAddress(PresaleInfo[7])
        setTokenLogo(
          'https://moonbase.zapto.org:13001/uploads/' + presaleAddress + '-logo.png'
        )
        setTokenBanner(
          'https://moonbase.zapto.org:13001/uploads/' + presaleAddress + '-banner.png'
        )
        setSoftCap(Number(PresaleInfo[2]) / 10 ** 18)
        let allIn
        const totalTokens = 1000000000
        if (Number(totalTokens) === 1000000000) {
          allIn = true
        } else {
          allIn = false
        }
        setAllIn(allIn)
        setLiquidiity(Number(PresaleInfo[4]))
        setStartTime(new Date(Number(PresaleInfo[5]) * 1000).toLocaleString())
        setEndTime(new Date(Number(PresaleInfo[1]) * 1000).toLocaleString())

        setStartCounterTime(Number(PresaleInfo[5]))
        setEndCounterTime(Number(PresaleInfo[1]))

        setDepositedAmount(Number(PresaleInfo[3]) / 10 ** 18)
        setMaxBuyAmount(Number(PresaleInfo[9]) / 10 ** 18)
        setSaleLive(PresaleInfo[0])

        const PresaleRefInfo = await publicClient.readContract({
          address: presaleAddress,
          abi: FairLaunchAbi,
          functionName: 'getOtherInfo'
        })
        if (Number(PresaleRefInfo[3]) > 0) {
          setRefPerc(Number(PresaleRefInfo[3]))
          setRefCounts(Number(PresaleRefInfo[4]))
          setTotalRefAmounts(Number(PresaleRefInfo[6]) / 10 ** 18)
          const RefDatas = await publicClient.readContract({
            address: multicallAddress,
            abi: MultiCallAbi,
            functionName: 'presaleUserRefInfo',
            args: [presaleAddress]
          })
          // setRefAddresses(RefDatas[0])
          // setRefUserAmounts(RefDatas[1])
          let refData;
          let refDatas = []
          for (let i = 0; i < RefDatas[0].length; i++) {
            refData = {
              address: RefDatas[0][i],
              amount: RefDatas[1][i] / (10 ** 18)
            }
            refDatas.push(refData)
          }
          refDatas = sortBy(refDatas, ['amount']).reverse();
          setRefDatas(refDatas)
        }
        if (address === undefined) {
          address = '0x0000000000000000000000000000000000000000'
        }

        if (address) {
          accountBalance = await BaseWeb3.eth.getBalance(address)
          accountBalance = BaseWeb3.utils.fromWei(accountBalance, 'ether')
          setAccountBalance(accountBalance)
          if (tab === 0) {
            setInputBalance(accountBalance)
          } else {
            setInputBalance(tokenBalance)
          }
          const PresaleOtherInfo = await publicClient.readContract({
            address: presaleAddress,
            abi: FairLaunchAbi,
            functionName: 'getPresaleOtherInfo',
            args: [address]
          })
          setTokenDescription(PresaleOtherInfo[0])
          const presaleDepositedAmount = Number(PresaleOtherInfo[1])
          setDepositUserAmount(Number(presaleDepositedAmount) / 10 ** 18)
          setInvestors(Number(PresaleOtherInfo[2]))
          setRefundable(PresaleOtherInfo[3])
          setClaimable(PresaleOtherInfo[4])
          setOwner(PresaleOtherInfo[5])

          const PresaleSiteInfo = await publicClient.readContract({
            address: presaleAddress,
            abi: FairLaunchAbi,
            functionName: 'getPresaleSiteInfo'
          })
          setWebsite(PresaleSiteInfo[0])
          setTwitter(PresaleSiteInfo[1])
          setTelegram(PresaleSiteInfo[2])
          setDiscord(PresaleSiteInfo[3])
          if (
            presaleDepositedAmount > 0 &&
            PresaleOtherInfo[4] === true &&
            state === 2
          ) {
            const vestedAmount = await publicClient.readContract({
              address: presaleAddress,
              abi: FairLaunchAbi,
              functionName: 'getVestedAmount',
              args: [address]
            })
            setClaimableAmount(Number(vestedAmount) / 10 ** 18)
            const withrawedAmount = await publicClient.readContract({
              address: presaleAddress,
              abi: FairLaunchAbi,
              functionName: 'claimed',
              args: [address]
            })
            setWithdrawAmount(Number(withrawedAmount) / 10 ** 18)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (creating === false) {
      FetchData()
    }
  }, [chain?.id, creating, presaleAddress])
  useEffect(() => {
    const FetchInfo = async () => {
      try {
        await fetch(`https://base-fairlaunch-api-0df02e9f3f15.herokuapp.com/api/getOne/${owner}`, {
          method: 'GET'
        })
          .then(async res => {
            let data = await res.json()
            if (data.length > 0) {
              if (data[0].imageUrl === 'default') {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              } else {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/' + data[0].imageUrl)
              }
              setOwnerName(data[0].name)
            } else {
              setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              setOwnerName('@mooning')
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (owner) {
      FetchInfo()
    }
  }, [owner])

  const statusIcons = {
    0: <StatusUpcoming />,
    1: <StatusSaleLive />,
    2: <StatusSaleClosed />
  }

  const statusClasses = {
    0: 'launchpad-status-upcoming',
    1: 'launchpad-status-sale-live',
    2: 'launchpad-status-closed'
  }
  let progress = (depositedAmount * 100) / softCap
  let currentRaiseAmount = progress
  if (progress > 100) {
    progress = 100
  }
  const CapLimit = softCap
  const LaunchStatus =
    saleLive === true
      ? 'Sale Ends'
      : state === 2
        ? 'Sale Ended'
        : 'Sale Start In'
  const ButtonText =
    saleLive === true ? (state === 1 ? 'Buy with ETH' : 'Claim') : 'Wait'
  const maxBuy = maxBuyAmount
  const currentRaise =
    depositedAmount + '(' + currentRaiseAmount.toFixed(3) + '%)'

  const setMaxAmount = async () => {
    // if (accountBalance > 0) accountBalance = accountBalance - 0.0001
    if (tab === 0) {
      setAmount(accountBalance)
    }
  }

  const setSwapMaxAmount = async () => {
    // if (accountBalance > 0) accountBalance = accountBalance - 0.0001
    if (tab === 0) {
      setAmount(accountBalance)
    } else {
      setAmount(tokenBalance)
    }
  }

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts;
        amounts = await publicClient.readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'balanceOf',
          args: [address]
        })
        let allowance;
        allowance = await publicClient.readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'allowance',
          args: [address, routerAddress]
        })
        setTokenAllowance(Number(allowance) / (10 ** 18))
        setTokenBalance(BaseWeb3.utils.fromWei(String(amounts), 'ether'))
      } catch (e) {
        console.error(e)
      }
    }
    if (address && tokenAddress) {
      FetchAmount()
    }
  }, [tokenAddress, address, creating])

  useEffect(() => {
    if (tab === 0) {
      setInputBalance(accountBalance)
      setAmount(tokenOutAmount)
    } else {
      setInputBalance(tokenBalance)
      setAmount(tokenOutAmount)
    }
  }, [tab])

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts;
        let path;
        if (tab === 0) {
          path = [WETHAddress, tokenAddress]
          amounts = await publicClient.readContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'getAmountsOut',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), path]
          })
        } else {
          path = [tokenAddress, WETHAddress]
          amounts = await publicClient.readContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'getAmountsOut',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), path]
          })
        }
        setTokenOutAmount(Number(amounts[1]) / (10 ** 18))

      } catch (e) {
        console.error(e)
      }
    }
    if (Number(tokenAmount)) {
      FetchAmount()
    } else {
      setTokenOutAmount(0)
    }
  }, [tab, tokenAmount, creating])

  const onTokenSwap = async () => {
    try {
      setCreating(true)
      let swap;
      if (tab === 0) {
        const path = [WETHAddress, tokenAddress]
        const timestamp = (new Date().getTime() / 1000) + 300;
        swap = await writeContract({
          address: routerAddress,
          abi: routerAbi,
          functionName: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
          value: BaseWeb3.utils.toWei(String(tokenAmount), 'ether'),
          args: [0, path, address, timestamp.toFixed(0)]
        })
        await waitForTransaction({
          hash: swap.hash
        })
      } else {
        if (tokenAllowance > 0) {
          const path = [tokenAddress, WETHAddress]
          const timestamp = (new Date().getTime() / 1000) + 300;
          swap = await writeContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), 0, path, address, timestamp.toFixed(0)]
          })
          await waitForTransaction({
            hash: swap.hash
          })
        } else {
          let max = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
          let approve;
          approve = await writeContract({
            address: tokenAddress,
            abi: TokenAbi,
            functionName: 'approve',
            args: [routerAddress, max]
          })
          await waitForTransaction({
            hash: approve.hash
          })
        }
        setInputToken(inputToken)
      }
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully ${tokenAmount} ETH swapped`)
    } catch (err) {
      toast.error(
        'There is a problem with your Swap. Try again later'
      )
      setCreating(false)
    }
  }

  const onChangeTab0 = () => {
    setTab(0)
  };

  const onChangeTab1 = () => {
    setTab(1)
  };


  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar">
            </div>
            <div className="headerMargin" />
            <div className="MainDashboard ResponsiveFlexLayout">
              {/* <iframe
                  src={`https://dexscreener.com/base/${tokenAddress}?embed=1&trades=0&info=0&theme=light`}
                  className="chart"
                  title="chart"
                /> */}

              <section className="chart">
                <>
                  <br />
                  <section>
                    <ClaimCard
                      status={state}
                      RugProof={true}
                      AllIn={allIns}
                      CapType={'Soft Cap'}
                      CapLimit={softCap} // ETH
                      tokenName={tokenName}
                      Logo={<img src={tokenLogo} className="claim-eth-logo" />}
                      about={tokenDescription}
                      tokenAddress={tokenAddress}
                      tokenSymbol={tokenSymbol}
                      tokenDecimals={18}
                      tokenTotalSupply={1000000000}
                      tokenSupplySale={tokenSupply}
                      tokenSupplyLiquidity={liquidity}
                      tokenUnsoldTokens={'Burned 🔥'}
                      saleStarts={startTime}
                      saleEnds={endTime}
                      tokenCover={tokenBanner}
                      website={website}
                      telegram={telegram}
                      twitter={twitter}
                      discord={discord}
                      bannerPreview={bannerPreview}
                      ownerName={ownerName}
                      ownerAddress={owner}
                      refundable={refundable}
                      refPerc={refPerc}
                      refCounts={refCounts}
                      totalRefAmounts={totalRefAmounts}
                      refDatas={refDatas}
                      claimable={claimable}
                    />
                  </section>
                </>
              </section>



              <section className="ClaimLeftColumn">
                <p className="avoid-scam avoid-scam-text">
                  Avoid scam links! Make sure the website is <b>moonbased.io</b>
                </p>


                {/* <BigCountdown
                  futureDate={
                    1720547088
                  }
                /> */}

                {state === 2 && claimable === true ?
                  <div className="claim-card">
                    <section className='swapBox1'>
                      <button className='HeaderContent3Button' onClick={onChangeTab0} style={{ background: tab === 0 ? '#5272f7' : '', color: tab === 0 ? 'white' : '#5272f7' }}>Buy</button>
                      <button className='HeaderContent3Button' onClick={onChangeTab1} style={{ background: tab === 1 ? '#5272f7' : '', color: tab === 1 ? 'white' : '#5272f7' }}>Sell</button>
                    </section>
                    <div className="launchpad-progress-container">
                      <div className="launchpad-progress-endpoints">
                        <span className="launchpad-progress-text">
                        </span>
                        <span className="launchpad-progress-text">
                          Balance: &nbsp;{Number(inputBalance).toFixed(3)} {tab === 0 ? 'ETH' : tokenName}
                        </span>
                      </div>
                      <section className="InputSection_Description">
                        <section className="inputPanel">
                          <section
                            className="inputPanelHeader"
                            style={{ marginTop: '12px' }}
                          >
                            <div className='InputContainer'>
                              <MaxInput
                                placeholder="Enter Amount"
                                label=""
                                type="number"
                                changeValue={setAmount}
                                value={tokenAmount}
                              />
                            </div>
                          </section>
                          <div
                            className="LpBalance sub"
                          >
                            <img src={tab === 0 ? ETHIcon : tokenLogo} className="claim-eth-token-logo" />
                            <p
                              className="Text1 BuyMax"
                              onClick={setSwapMaxAmount}
                            >
                              MAX
                            </p>
                          </div>
                        </section>
                      </section>
                    </div>
                    <p className="arrow">↑ ↓</p>
                    <div className="launchpad-progress-container">
                      <section className="InputSection_Description">
                        <section className="inputPanel">
                          <section
                            className="inputPanelHeader"
                            style={{ marginTop: '12px' }}
                          >
                            <div className='InputContainer'>
                              <input
                                placeholder=""
                                label=""
                                type="number"
                                value={tokenOutAmount}
                                disabled
                              />
                            </div>
                          </section>
                          <div
                            className="LpBalance sub"
                          >
                            <img src={tab === 1 ? ETHIcon : tokenLogo} className="claim-eth-token-logo" />
                          </div>
                        </section>
                      </section>
                    </div>
                    {creating === false ?
                      <button onClick={onTokenSwap} className="claim-button">{tab === 1 && tokenAllowance === 0 ? 'Approve token First' : 'Swap Tokens'}</button>
                      :
                      <>
                        <div className="loadingBox">
                          <p className="Text1" style={{ color: 'black' }}>
                            Loading...
                          </p>
                          <ClipLoader
                            color={'#afccc6'}
                            loading={creating}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div></>}
                    <div className="token-info-item" style={{ marginTop: '10px' }}>
                    </div>
                  </div>
                  :
                  <></>}
                {refundable === false ?
                  <div className="claim-card">
                    {state !== 2 ? (
                      <>
                        <p
                          className="claim-text"
                          style={{
                            fontSize: '18px',
                            margin: 'auto',
                            width: '100%',
                            textAlign: 'center'
                          }}
                        >
                          {LaunchStatus}
                        </p>
                        <BigCountdown
                          futureDate={
                            saleLive
                              ? Number(endCounterTime)
                              : state === 2
                                ? new Date().toLocaleString()
                                : Number(startCounterTime)
                          }
                        />
                      </>
                    ) : (
                      state === 2 ?
                        <div className="claim-card2">
                          <div className="commuity-progress-container">
                            <a
                              className="claim-button"
                              disabled={state === 1 ? false : true}
                              href={
                                // `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=` +
                                `https://dexscreener.com/base/` +
                                tokenAddress
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {'Chart ' + tokenSymbol}
                            </a>
                          </div>
                        </div>
                        :
                        <></>
                      // <p
                      //   className="claim-text"
                      //   style={{
                      //     fontSize: '14px',
                      //     margin: 'auto',
                      //     width: '100%',
                      //     textAlign: 'center'
                      //   }}
                      // >
                      //   {LaunchStatus}
                      // </p>
                    )}

                    <div className="launchpad-progress-container">
                      <section className="InputSection_Description">
                        <section className="inputPanel">
                          {state === 1 ? (
                            <>
                              <section
                                className="inputPanelHeader"
                                style={{ marginTop: '12px' }}
                              >
                                <Input
                                  placeholder="Enter ETH Quantity"
                                  label=""
                                  type="number"
                                  changeValue={setAmount}
                                  value={tokenAmount}
                                />
                              </section>
                              <div className="LpBalance sub">
                                <img src={ETHIcon} className="claim-eth-token-logo" />
                                <p
                                  className="Text1 BuyMax"
                                  onClick={setMaxAmount}
                                >
                                  MAX
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </section>
                      </section>
                      <>
                        {creating === false ? (
                          <>
                            {(state === 2 && claimable === true) ? (
                              <></>
                            ) : (
                              <button
                                className="claim-button"
                                disabled={state === 1 ? false : true}
                                onClick={onTokenDeposit}
                              >
                                {ButtonText}
                              </button>
                            )}

                            {depositUserAmount > 0 ? (
                              state === 1 ? (
                                <button
                                  className="claim-button"
                                  disabled={false}
                                  onClick={onTokenEmergency}
                                >
                                  Emergency Withdraw
                                </button>
                              ) : claimable === true ? (
                                <button
                                  className="claim-button"
                                  disabled={false}
                                  onClick={onClaim}
                                >
                                  Claim Token
                                </button>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                            {owner === address && (state === 1 || state === 2) ? (
                              <>
                                {claimable === false && refundable === false ? (
                                  <button
                                    className="claim-button"
                                    disabled={claimable}
                                    onClick={onCancel}
                                  >
                                    Cancel Presale
                                  </button>
                                ) : (
                                  <></>
                                )}
                                {refundable === false &&
                                  state === 2 &&
                                  claimable === false ? (
                                  <button
                                    className="claim-button"
                                    disabled={
                                      refundable ||
                                      depositedAmount < softCap ||
                                      claimable ||
                                      state === 1
                                    }
                                    onClick={onFinalize}
                                  >
                                    Finalize Presale
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {/* <div className="loadingBox">
                              <p className="Text1" style={{ color: 'black' }}>
                                Loading...
                              </p>
                              <ClipLoader
                                color={'#afccc6'}
                                loading={creating}
                                size={30}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div> */}
                          </>
                        )}
                      </>
                    </div>
                  </div>
                  :
                  <>
                    {depositUserAmount > 0 &&
                      state === 2 ? (
                      <div className="claim-card">
                        <button
                          className="claim-button"
                          disabled={false}
                          onClick={onWithdraw}
                        >
                          Withdraw your deposited ETH
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                }
                <br />
                <div className="claim-card">
                  <div className="launchpad-progress-container">
                    <div className="launchpad-progress-text">
                      Progress ({currentRaiseAmount.toFixed(3)}%)
                    </div>
                    <div className="launchpad-progress-bar">
                      <div
                        className="launchpad-progress-bar-filled"
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                    <div className="launchpad-progress-endpoints">
                      <span className="launchpad-progress-text">
                        {depositedAmount.toFixed(2)} ETH
                      </span>
                      <span className="launchpad-progress-text">
                        {CapLimit} ETH
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                {refPerc > 0 ?
                  <>

                    <div className="claim-card">
                      <div className="token-info-item">
                        <span className="token-info-label mx-auto">
                          <h3 style={{ marginTop: '0px' }}>
                            Affiliate Program
                          </h3>
                        </span>
                      </div>
                      <div className="token-info-item">
                        <span className="token-info-label">
                          Share this pool to earn more rewards
                        </span>
                      </div>
                      <br />
                      <div className="fair-launch-box" style={{ wordBreak: 'break-all' }}>
                        {referlink}
                      </div>
                      <br />
                      <button
                        onClick={copyAddress(referlink)}
                        className="save-button"
                      >
                        {isTooltipDisplayed ? (
                          <span className="tooltiptext center-aligned">Copied</span>
                        ) : <>
                          Copy&nbsp;
                          <img src={CopyIcon} /></>}
                      </button>

                    </div>
                  </> : <></>}

                <br />
                <div className="claim-card">
                  <div className="token-info-item">
                    <span className="token-info-label">Sale Status</span>
                    <span className="token-info-value">
                      <div
                        className={`launchpad-status-text ${statusClasses[claimable === true ? 1 : state]}`}
                        style={{ marginTop: '-4px' }}
                      >
                        <div className="launchpad-status-icon">
                          {statusIcons[claimable === true ? 1 : state]}
                        </div>
                        <span className="launchpad-status-text">
                          {state === 1
                            ? 'Sale Live'
                            : state === 0
                              ? 'Upcoming'
                              : refundable === false ? claimable === true ? 'Live' : 'Closed' : 'Cancelled'}
                        </span>
                      </div>
                    </span>
                  </div>
                  <hr />
                  <div className="token-info-item">
                    <span className="token-info-label">Max Buy</span>
                    <span className="token-info-value">{maxBuy} ETH</span>
                  </div>
                  <hr />
                  <div className="token-info-item">
                    <span className="token-info-label">Current Raise</span>
                    <span className="token-info-value">{currentRaise}</span>
                  </div>
                  <hr />
                  {depositUserAmount > 0 ? (
                    <>
                      <div className="token-info-item">
                        <span className="token-info-label">
                          Your Deposited Amount
                        </span>
                        <span className="token-info-value">
                          {depositUserAmount} ETH
                        </span>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}

                  {claimableAmount - withdrawAmount > 0 ? (
                    <>
                      <div className="token-info-item">
                        <span className="token-info-label">
                          Your Claimable Tokens
                        </span>
                        <span className="token-info-value">
                          {(claimableAmount - withdrawAmount).toFixed(0)}
                        </span>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}

                  {withdrawAmount > 0 ? (
                    <>
                      <div className="token-info-item">
                        <span className="token-info-label">
                          Your Withdrew Tokens
                        </span>
                        <span className="token-info-value">
                          {withdrawAmount.toFixed(0)}
                        </span>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}
                </div>

              </section>
            </div>

          </div>
        }
      </div >
      <Footer />
    </div >
  )
}

export default App
