/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNetwork } from 'wagmi'
import '../App.css'
import MultiCallAbi from '../config/MultiCallAbi.json'
import '../styles/MainContainer.css'
import LeaderBox from '../components/LeaderBox'
import LaunchpadCard from '../components/LaunchpadCard'
import ClipLoader from 'react-spinners/ClipLoader'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'
import { Link } from 'react-router-dom'
import { sortBy } from 'lodash'

const publicClient = createPublicClient({
    chain: base,
    transport: http()
})
const App = () => {
    const multicallAddress = '0x4Ec30E9D706c881b0B6D67929025C77bA473214D'
    const { chain } = useNetwork()
    let [loading, setLoading] = useState(false)
    const [presaleLists, setPresaleListDatas] = useState([])
    const [currentLength, setCurrentLength] = useState(0)
    const [totalLength, setTotalLength] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')

    const handleSearchChange = event => {
        setSearchQuery(event.target.value)
    }

    useEffect(() => {
        const FetchData = async () => {
            try {
                setLoading(true)
                const mainInfo = await publicClient.readContract({
                    address: multicallAddress,
                    abi: MultiCallAbi,
                    functionName: 'presaleInfo'
                })
                const otherInfo = await publicClient.readContract({
                    address: multicallAddress,
                    abi: MultiCallAbi,
                    functionName: 'presaleOtherInfo'
                })
                setTotalLength(Number(mainInfo[0].length) + totalLength)
                const length = Number(mainInfo[0].length)
                if (length > 0) {
                    let presaleListsDatas = []
                    let presaleData
                    for (let i = length - 1; i >= 0; i--) {
                        const name = otherInfo[0][i]
                        let logoUrl = 'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-logo.png'
                        presaleData = {
                            tokenName: name,
                            logoUrl: logoUrl,
                            address: otherInfo[2][i],
                        }
                        presaleListsDatas.push(presaleData)
                    }
                    setPresaleListDatas(presaleListsDatas)
                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
                console.error(e)
            }
        }
        FetchData()
    }, [chain?.id])

    const [coinLists, setCoinListDatas] = useState([])
    useEffect(() => {
        const FetchData = async () => {
            try {
                setLoading(true)
                const mainInfo = await publicClient.readContract({
                    address: multicallAddress,
                    abi: MultiCallAbi,
                    functionName: 'coinInfo'
                })
                const otherInfo = await publicClient.readContract({
                    address: multicallAddress,
                    abi: MultiCallAbi,
                    functionName: 'coinOtherInfo'
                })
                const length = Number(mainInfo[0].length)
                setTotalLength(length + totalLength)
                if (length > 0) {
                    let coinListsDatas = []
                    let coinData
                    for (let i = length - 1; i >= 0; i--) {
                        if (otherInfo[0][i] === true) {
                            let logoUrl =
                                'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-logo.png'
                            coinData = {
                                address: otherInfo[2][i],
                                tokenName: mainInfo[0][i],
                                logoUrl: logoUrl,
                            }
                            coinListsDatas.push(coinData)
                        } else {
                            setLoading(false)
                        }
                    }
                    setCoinListDatas(coinListsDatas)
                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
                console.error(e)
            }
        }
        FetchData()
    }, [chain?.id])

    const [combinedLists, setCombinedLists] = useState([])

    useEffect(() => {
        let combineListDatas = [...coinLists, ...presaleLists];
        let addresses = '';
        for (let i = 0; i < combineListDatas.length; i++) {
            addresses = combineListDatas[i].address + "," + addresses
        }
        const FetchInfo = async () => {
            try {
                await fetch(`https://api.dexscreener.com/latest/dex/tokens/${addresses}`, {
                    method: 'GET'
                })
                    .then(async res => {
                        let data = await res.json()
                        for (let i = 0; i < data.pairs.length; i++) {
                            for (let j = 0; j < combineListDatas.length; j++) {
                                if (combineListDatas[j].address === data.pairs[i].baseToken.address) {
                                    if (data.pairs[i].labels)
                                        if (data.pairs[i].labels[0] === 'v2') {
                                            combineListDatas[j].priceChange = data.pairs[i].priceChange.h24
                                            combineListDatas[j].liquidity = data.pairs[i].liquidity.usd
                                        }
                                }
                            }
                        }
                        combineListDatas = sortBy(combineListDatas, ['priceChange']).reverse();
                        setCombinedLists(combineListDatas)
                    })
            } catch (e) {
                console.error(e)
            }
        }
        if (combineListDatas.length > 0)
            FetchInfo()

    }, [coinLists, presaleLists])

    const shouldRenderCoinCard = item => {
        // Define criteria for which items should be rendered as CoinCard
        return 'lpBurnAmount' in item
    }

    const launchTypes = ['All Launches', 'Fair Launches', 'Stealth Launches']
    const statusFilters = ['Status', 'Upcoming', 'Closed', 'Live']
    const [statusFilter, setStatusFilter] = useState('Status')
    const history = useHistory()

    const handleLaunchTypeChange = type => {
        const paths = {
            'all launches': '/AllLaunches',
            'fair launches': '/FairLaunches',
            'stealth launches': '/CommunityFunded',
            'my contributions': '/MyContributions'
        }

        const path = paths[type.toLowerCase()]
        if (path) {
            history.push(path)
        }
    }

    const handleStatusFilterChange = status => {
        setStatusFilter(status)
    }

    const shouldRenderCard = item => {
        if (item.liquidity) {
            return true
        }
        if (item.liquidity > 0) {
            return true
        }
        return false
    }

    function isMobile() {
        try {
            document.createEvent('TouchEvent')
            return true
        } catch (e) {
            return false
        }
    }
    let filteredDatas = combinedLists.filter(item => shouldRenderCard(item))

    return (
        <div>
            <div className="GlobalContainer">
                <div style={{ zIndex: 1 }}>
                    <TopBar />
                    <div className="navBar"></div>
                    <div className="headerMargin" />
                    <div className="MainDashboard">
                        <>
                            <section className="FairLaunchFlexLayout">
                                <section className="">
                                    <p style={{ textAlign: 'center', fontSize: '30px' }}>
                                        Leader&nbsp;Board
                                    </p>
                                    <br />
                                    {/* Search field */}
                                    <section>
                                        <div className="filter-row-offset">
                                            <div className="filter-row">
                                                <div className="filter-buttons-row">
                                                    <div className="filter-dropdown-row">
                                                        <select
                                                            className="launch-type-dropdown"
                                                            styles={{
                                                                control: styles => ({
                                                                    ...styles,
                                                                    backgroundColor: '#06021d',
                                                                    color: 'white'
                                                                }),
                                                                option: styles => ({
                                                                    ...styles,
                                                                    backgroundColor: '#3554A2',
                                                                    color: 'white'
                                                                }),
                                                                singleValue: styles => ({
                                                                    ...styles,
                                                                    color: 'white'
                                                                }),
                                                                input: styles => ({
                                                                    ...styles,
                                                                    caretColor: 'transparent'
                                                                }),
                                                                dropdownIndicator: styles => ({
                                                                    ...styles,
                                                                    color: '9900c8'
                                                                }),
                                                                placeholder: styles => ({
                                                                    gridArea: '1 / 1 / 2 / 3',
                                                                    color: '#777777',
                                                                    marginLeft: '2px',
                                                                    marginRight: '2px',
                                                                    boxSizing: 'border-box'
                                                                })
                                                            }}
                                                            onChange={e =>
                                                                handleLaunchTypeChange(e.target.value)
                                                            }
                                                        >
                                                            {launchTypes.map((type, index) => (
                                                                <option key={index} value={type.toLowerCase()}>
                                                                    {type}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            className="status-filter-dropdown"
                                                            onChange={e =>
                                                                handleStatusFilterChange(e.target.value)
                                                            }
                                                        >
                                                            {statusFilters.map((status, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={status.toLowerCase()}
                                                                >
                                                                    {status}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {!isMobile() ?
                                                        <Link
                                                            to="/MyContributions"
                                                            className="my-contributions-button"
                                                        >
                                                            My&nbsp;Contributions
                                                        </Link>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                                <div className="search-field">
                                                    <SearchIcon className="search-field-icon" />
                                                    <input
                                                        className="searchField search-field-input"
                                                        type="text"
                                                        placeholder="Search token"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    {combinedLists.length > 0 ? (
                                        <>
                                            <div className="leaderboard-card">
                                                {combinedLists
                                                    .filter(item => shouldRenderCard(item))
                                                    .filter(item =>
                                                        item.tokenName
                                                            ?.toLowerCase()
                                                            .includes(searchQuery.toLowerCase())
                                                    )
                                                    .map((item, i) => {
                                                        return (
                                                            <LeaderBox
                                                                key={i}
                                                                tokenAddress={item.address}
                                                                tokenName={item.tokenName}
                                                                LogoUrl={
                                                                    <img
                                                                        src={item.logoUrl}
                                                                        className="leader-eth-logo"
                                                                    />
                                                                }
                                                                liquidity={item.liquidity}
                                                                priceChange={item.priceChange}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                            {filteredDatas.length === 0 && (
                                                <div className="loadingBox">
                                                    <p className="Nodatas Text1">
                                                        No datas yet
                                                    </p>
                                                </div>
                                            )}
                                            {loading === true ? (
                                                <div className="loadingBox">
                                                    <ClipLoader
                                                        color={'#afccc6'}
                                                        loading={true}
                                                        size={50}
                                                        aria-label="Loading Spinner"
                                                        data-testid="loader"
                                                    />
                                                </div>
                                            ) : currentLength > 0 ? (
                                                <></>
                                            ) : (
                                                // <p className="loadMoreText" onClick={loadMoreLists()}>
                                                //   Load more ...
                                                // </p>
                                                <></>
                                            )}
                                        </>
                                    ) : totalLength === 0 || loading === false ? (
                                        <div className="loadingBox">
                                            <p className="Nodatas Text1">
                                                No datas yet
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="EmptyLaunchpad">
                                            <div className="loadingBox">
                                                <p className="Text1" style={{ color: 'black' }}>
                                                    Loading...
                                                </p>
                                                <ClipLoader
                                                    color={'#afccc6'}
                                                    loading={true}
                                                    size={50}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </section>
                            </section>
                        </>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default App
