/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import '../App.css'
import TokenAbi from '../config/TokenAbi.json'
import '../styles/MainContainer.css'
import Footer from '../components/Footer'
import Web3 from 'web3'
import TokenCard from '../components/TokenCard.jsx'
import TopBar from '../components/TopBar'
import { useQueryParam, StringParam } from 'use-query-params'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'
import { writeContract, readContract, waitForTransaction } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import MaxInput from '../components/MaxInput.tsx'
import routerAbi from '../config/UniswapRouterAbi.json'
import ETHIcon from '../icons/eth.png'
import { toast } from 'react-hot-toast'

const publicClient = createPublicClient({
  chain: base,
  transport: http()
})

const App = () => {
  let [coinAddress] = useQueryParam('address', StringParam)
  let routerAddress = '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24';
  let WETHAddress = '0x4200000000000000000000000000000000000006';
  const BASE_PROVIDER_URL = 'https://base-pokt.nodies.app'
  let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [tokenLogo, setTokenLogo] = useState('')
  const [tokenBanner, setTokenBanner] = useState('')
  const [tokenSupply, setTokenSupply] = useState(0)
  const [liquidity, setLiquidiity] = useState(0)
  const [tokenDescription, setTokenDescription] = useState('')
  const [state, setState] = useState()
  const [tokenDecimal, setTokenDecimal] = useState('')
  let [loading, setLoading] = useState(false)
  const [burnAmount, setBurnAmount] = useState(0)
  const [website, setWebsite] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [twitter, setTwitter] = useState('')
  let [inputBalance, setInputBalance] = useState(0)
  let [tokenBalance, setTokenBalance] = useState(0)
  let [tokenAllowance, setTokenAllowance] = useState(0)
  const [tokenAmount, setAmount] = useState(0)
  let [creating, setCreating] = useState(false)
  let [accountBalance, setAccountBalance] = useState(0)
  const [tokenOutAmount, setTokenOutAmount] = useState(0)
  const [owner, setOwner] = useState(0)
  const [ownerName, setOwnerName] = useState('')
  const [bannerPreview, setBannerPreview] = useState('')

  // const [tokenAllocatedLp, setTokenAllocatedLp] = useState(0)
  const [initialpLpETH, setInitialLpETH] = useState(0)

  let [tab, setTab] = useState(0);

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  useEffect(() => {
    const FetchData = async () => {
      try {
        const TokenInfo = await publicClient.readContract({
          address: coinAddress,
          abi: TokenAbi,
          functionName: 'getCoinInfo'
        })
        console.log('===== INFO =====')

        setTokenName(TokenInfo[0])
        setTokenSymbol(TokenInfo[1])
        setTokenAddress(coinAddress)
        setTokenLogo(
          'https://moonbase.zapto.org:13001/uploads/' + coinAddress + '-logo.png'
        )
        setTokenBanner(
          'https://moonbase.zapto.org:13001/uploads/'+ coinAddress + '-banner.png'
        )

        setTokenDecimal(Number(TokenInfo[3]))
        setTokenSupply(Number(TokenInfo[2]) / 10 ** Number(TokenInfo[3]))
        setLiquidiity(Number(TokenInfo[4]) / 10 ** 18)
        setBurnAmount(Number(TokenInfo[6]) / 10 ** 18)
        setWebsite(TokenInfo[8][0])
        setTelegram(TokenInfo[8][1])
        setDiscord(TokenInfo[8][2])
        setTwitter(TokenInfo[8][3])
        let status = 0
        if (Number(TokenInfo[4]) / 10 ** 18 > 0) {
          status = 1
        }
        setState(status)

        const CoinDescription = await publicClient.readContract({
          address: coinAddress,
          abi: TokenAbi,
          functionName: '_description'
        })
        setTokenDescription(CoinDescription)
        let CoinOwner
        if (coinAddress === '0xF3F2659a601E40Bf6F2A6C26925d4aD7F0b53468') {
          CoinOwner = '0x5a034abC89b0e3A0fdE3445ee769F99B8bCfd315';
        } else {
          CoinOwner = await publicClient.readContract({
            address: coinAddress,
            abi: TokenAbi,
            functionName: 'creator'
          })
        }
        setOwner(CoinOwner);
        if (address) {
          let accountBalance = await BaseWeb3.eth.getBalance(address)
          accountBalance = BaseWeb3.utils.fromWei(accountBalance, 'ether')
          setAccountBalance(accountBalance)
          if (tab === 0) {
            setInputBalance(accountBalance)
          } else {
            setInputBalance(tokenBalance)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    FetchData()
  }, [chain?.id, coinAddress, tab])

  useEffect(() => {
    const FetchInfo = async () => {
      try {
        await fetch(`https://base-fairlaunch-api-0df02e9f3f15.herokuapp.com/api/getOne/${owner}`, {
          method: 'GET'
        })
          .then(async res => {
            let data = await res.json()
            if (data.length > 0) {
              if (data[0].imageUrl === 'default') {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              } else {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/' + data[0].imageUrl)
              }
              setOwnerName(data[0].name)
            } else {
              setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              setOwnerName('@mooning')
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (owner) {
      FetchInfo()
    }
  }, [owner])

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts;
        amounts = await readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'balanceOf',
          args: [address]
        })
        let allowance;
        allowance = await readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'allowance',
          args: [address, routerAddress]
        })
        setTokenAllowance(Number(allowance) / (10 ** 18))
        setTokenBalance(BaseWeb3.utils.fromWei(String(amounts), 'ether'))
      } catch (e) {
        console.error(e)
      }
    }
    if (address && tokenAddress) {
      FetchAmount()
    }
  }, [tokenAddress, address, creating])

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        // let poolAddress;
        // poolAddress = await readContract({
        //   address: tokenAddress,
        //   abi: TokenAbi,
        //   functionName: 'poolAddress'
        // })
        // let amounts;
        // amounts = await readContract({
        //   address: tokenAddress,
        //   abi: TokenAbi,
        //   functionName: 'balanceOf',
        //   args: [poolAddress]
        // })
        let lpETHAmount;
        lpETHAmount = await readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'lpETHAmount'
        })
        // setTokenAllocatedLp((Number(amounts) * 100 / 10 ** 18) / 1000000000)
        setInitialLpETH(BaseWeb3.utils.fromWei(String(lpETHAmount), 'ether'))
      } catch (e) {
        console.error(e)
      }
    }
    if (address && tokenAddress) {
      FetchAmount()
    }
  }, [tokenAddress, address, creating])

  useEffect(() => {
    if (tab === 0) {
      setInputBalance(tokenBalance)
      setAmount(tokenOutAmount)
    } else {
      setInputBalance(accountBalance)
      setAmount(tokenOutAmount)
    }
  }, [tab])


  const setMaxAmount = async () => {
    if (accountBalance > 0) accountBalance = accountBalance - 0.0001
    if (tab === 0) {
      setAmount(accountBalance)
    } else {
      setAmount(tokenBalance)
    }
  }

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts;
        let path;
        if (tab === 0) {
          path = [WETHAddress, tokenAddress]
          amounts = await publicClient.readContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'getAmountsOut',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), path]
          })
        } else {
          path = [tokenAddress, WETHAddress]
          amounts = await publicClient.readContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'getAmountsOut',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), path]
          })
        }
        setTokenOutAmount(Number(amounts[1]) / (10 ** 18))

      } catch (e) {
        console.error(e)
      }
    }
    if (Number(tokenAmount)) {
      FetchAmount()
    } else {
      setTokenOutAmount(0)
    }
  }, [tab, tokenAmount, creating])

  const onTokenSwap = async () => {
    try {
      setCreating(true)
      let swap;
      if (tab === 0) {
        const path = [WETHAddress, tokenAddress]
        const timestamp = (new Date().getTime() / 1000) + 300;
        swap = await writeContract({
          address: routerAddress,
          abi: routerAbi,
          functionName: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
          value: BaseWeb3.utils.toWei(String(tokenAmount), 'ether'),
          args: [0, path, address, timestamp.toFixed(0)]
        })
        await waitForTransaction({
          hash: swap.hash
        })
      } else {
        if (tokenAllowance > 0) {
          const path = [tokenAddress, WETHAddress]
          const timestamp = (new Date().getTime() / 1000) + 300;
          swap = await writeContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), 0, path, address, timestamp.toFixed(0)]
          })
          await waitForTransaction({
            hash: swap.hash
          })
        } else {
          let max = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
          let approve;
          approve = await writeContract({
            address: tokenAddress,
            abi: TokenAbi,
            functionName: 'approve',
            args: [routerAddress, max]
          })
          await waitForTransaction({
            hash: approve.hash
          })
        }
      }
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully ${tokenAmount} ETH swapped`)
    } catch (err) {
      toast.error(
        'There is a problem with your Swap. Try again later'
      )
      setCreating(false)
    }
  }

  const onChangeTab0 = () => {
    setTab(0)
  };

  const onChangeTab1 = () => {
    setTab(1)
  };

  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar">
            </div>
            <div className="headerMargin" />
            <div className="MainDashboard ResponsiveFlexLayout">

              {/* <iframe
                  src={`https://dexscreener.com/base/${tokenAddress}?embed=1&trades=0&info=0&theme=light`}
                  className="chart"
                  title="chart"
                /> */}

              <section className="chart">
                <>
                  <br />
                  <br />
                  <section>
                    <TokenCard
                      status={state}
                      RugProof={burnAmount > 0 ? true : false}
                      // AllIn={allIns}
                      tokenName={tokenName}
                      Logo={<img src={tokenLogo} className="claim-eth-logo" />}
                      about={tokenDescription}
                      tokenAddress={tokenAddress}
                      tokenSymbol={tokenSymbol}
                      tokenDecimals={tokenDecimal}
                      tokenTotalSupply={tokenSupply}
                      tokenSupplyLiquidity={liquidity}
                      tokenUnsoldTokens={'Burned 🔥'}
                      tokenCover={tokenBanner}
                      website={website}
                      telegram={telegram}
                      twitter={twitter}
                      discord={discord}
                      bannerPreview={bannerPreview}
                      ownerName={ownerName}
                      ownerAddress={owner}
                      // tokenAllocatedLp={tokenAllocatedLp}
                      initialpLpETH={initialpLpETH}
                    />
                  </section>
                </>
              </section>
              <section className="ClaimLeftColumn">
                <br />
                <p className="avoid-scam avoid-scam-text">
                  Avoid scam links! Make sure the website is moonbased.io
                </p>
                <div className="claim-card">
                  <section className='swapBox1'>
                    <button className='HeaderContent3Button' onClick={onChangeTab0} style={{ background: tab === 0 ? '#5272f7' : '', color: tab === 0 ? 'white' : '#5272f7' }}>Buy</button>
                    <button className='HeaderContent3Button' onClick={onChangeTab1} style={{ background: tab === 1 ? '#5272f7' : '', color: tab === 1 ? 'white' : '#5272f7' }}>Sell</button>
                  </section>
                  <div className="launchpad-progress-container">
                    <div className="launchpad-progress-endpoints">
                      <span className="launchpad-progress-text">
                      </span>
                      <span className="launchpad-progress-text">
                        Balance: &nbsp;{Number(inputBalance).toFixed(3)} {tab === 0 ? 'ETH' : tokenName}
                      </span>
                    </div>
                    <section className="InputSection_Description">
                      <section className="inputPanel">
                        <section
                          className="inputPanelHeader"
                          style={{ marginTop: '12px' }}
                        >
                          <div className='InputContainer'>
                            <MaxInput
                              placeholder="Enter Amount"
                              label=""
                              type="number"
                              changeValue={setAmount}
                              value={tokenAmount}
                            />
                          </div>
                        </section>
                        <div
                          className="LpBalance sub"
                        >
                          <img src={tab === 0 ? ETHIcon : tokenLogo} className="claim-eth-token-logo" />

                          <p
                            className="Text1 BuyMax"
                            onClick={setMaxAmount}
                          >
                            MAX
                          </p>

                        </div>
                      </section>
                    </section>
                  </div>
                  <p className="arrow">↑ ↓</p>
                  <div className="launchpad-progress-container">
                    <section className="InputSection_Description">
                      <section className="inputPanel">
                        <section
                          className="inputPanelHeader"
                          style={{ marginTop: '12px' }}
                        >
                          <div className='InputContainer'>
                            <input
                              placeholder=""
                              label=""
                              type="number"
                              value={tokenOutAmount}
                              disabled
                            />
                          </div>
                        </section>
                        <div
                          className="LpBalance sub"
                        >
                          <img src={tab === 1 ? ETHIcon : tokenLogo} className="claim-eth-token-logo" />
                        </div>
                      </section>
                    </section>
                  </div>
                  {creating === false ?
                    <button onClick={onTokenSwap} className="claim-button">{tab === 1 && tokenAllowance === 0 ? 'Approve token First' : 'Swap Tokens'}</button>
                    :
                    <>
                      <div className="loadingBox">
                        <p className="Text1" style={{ color: 'black' }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={'#afccc6'}
                          loading={creating}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div></>}
                  <div className="token-info-item" style={{ marginTop: '10px' }}>
                  </div>
                </div>
                <div className="token-info-item" style={{ marginTop: '10px' }}>
                </div>
                <div className="claim-card">
                  <div className="commuity-progress-container">
                    <a
                      className="claim-button"
                      disabled={state === 1 ? false : true}
                      href={
                        // `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=` +
                        `https://dexscreener.com/base/` +
                        tokenAddress
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {'Chart ' + tokenSymbol}
                    </a>
                  </div>
                </div>
              </section>


            </div>
            <br />
            <br />

          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default App
