import React, { useState, useEffect } from 'react'
import sortBy from 'lodash/sortBy'
import rocketImage from '../icons/rocket.svg'
import iconHamburger from '../icons/hamburger.svg'
import LeftBar from './LeftBar'
import { readContract } from '@wagmi/core'
import { useAccount, useSwitchNetwork, useNetwork } from 'wagmi'
import MultiCallAbi from '../config/MultiCallAbi.json'
import { useWeb3Modal } from '@web3modal/react'
import MoonHeaderLink from '../components/MoonHeaderLink'
import { Link } from 'react-router-dom'
import arrow from '../icons/arrow.png'

const TopBar = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const multicallAddress = '0x4Ec30E9D706c881b0B6D67929025C77bA473214D'
  const [sortedList, setSortedList] = useState([])
  const [firstConnect, setFirstConnect] = useState(false)
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const handleHamburgerClick = () => {
    setIsExpanded(!isExpanded)
  }
  const [userName, setUserName] = useState(localStorage.getItem('user_name'))
  const [bannerPreview, setBannerPreview] = useState(localStorage.getItem('banner_preview'))
  const { open } = useWeb3Modal()
  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    // Add event listener for window resize
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setIsExpanded(false)
      }
    }
    window.addEventListener('resize', handleResize)
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleDocumentClick = event => {
    const leftBarElement = document.querySelector('.left-bar')
    const hamburgerMenuElement = document.querySelector('.left-bar-menu')

    // Check if the click is outside the left-bar
    if (
      !leftBarElement.contains(event.target) &&
      event.target !== hamburgerMenuElement
    ) {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    const FetchData = async () => {
      try {
        const getTrendingInfo = await readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'presaleTrending'
        })
        if (Number(getTrendingInfo[0].length) > 0) {
          let presaleListsDatas = [];
          let presaleData;
          let trendingInfos = [];
          for (let i = 0; i < getTrendingInfo[0].length; i++) {
            if (Number(getTrendingInfo[0][i]) > 0) {
              presaleData = { name: getTrendingInfo[1][i], address: getTrendingInfo[2][i], trending: Number(getTrendingInfo[0][i]) };
              trendingInfos.push(Number(getTrendingInfo))
              presaleListsDatas.push(presaleData);
            }
          }
          trendingInfos.push(Number(getTrendingInfo))
          const sortedList = sortBy(presaleListsDatas, ['trending']).reverse();
          setSortedList(sortedList)
          trendingInfos.sort(function (a, b) { return a - b });
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (address) {
      FetchData()
    }
  }, [address])

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(8453)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 8453) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])
  let wallet_address = localStorage.getItem('wallet_address');
  useEffect(() => {
    const FetchInfo = async () => {
      try {
        fetch(`https://base-fairlaunch-api-0df02e9f3f15.herokuapp.com/api/getOne/${address}`, {
          method: 'GET'
        })
          .then(async res => {
            let data = await res.json()
            let name;
            let image;
            if (data.length > 0) {

              if (data[0].imageUrl === 'default') {
                image = 'https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png'
              } else {
                image = 'https://moonbase.zapto.org:13001/uploads/' + data[0].imageUrl
              }
              name = data[0].name;
            } else {
              name = 'mooning'
              image = 'https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png'
            }
            setBannerPreview(image);
            setUserName(name);
            localStorage.setItem('user_name', name);
            localStorage.setItem('banner_preview', image);
            localStorage.setItem('wallet_address', address);
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (address !== wallet_address) {
      FetchInfo()
    }
  }, [address, bannerPreview, userName, wallet_address])

  const userProfile = `/profile/?address=${address}`

  function isMobile() {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  }
  return (
    <div>
      <LeftBar
        isExpanded={isExpanded}
        onHamburgerClick={handleHamburgerClick}
      />
      <div className='top-bar-box'>
        <div className="top-bar">
          <MoonHeaderLink className="tokenHeader" />
          <div className='tokenHeader1'>
            <div className='top-trending'>
              <img
                src={iconHamburger}
                alt="Menu"
                className="left-bar-menu"
                onClick={handleHamburgerClick}
              />
              {isMobile() ?
                <></> :
                <>
                  <img src={rocketImage} alt="rocket logo" className="rocket" />
                  <span className="top-bar-text">MOONING</span>
                  {sortedList.map(({ name, address, trending }, i) => (
                    <a className="top-bar-text" href={'/buy/?address=' + address} target='_blank' rel="noreferrer">#{name}</a>
                  ))}
                </>
              }
            </div>
            <div className="navConnectButtonBox">
              {!isConnected ? (
                <>
                  <button
                    className="navConnectButton"
                    type="submit"
                    onClick={() => {
                      onConnectWallet()
                    }}
                  >
                    Connect<span className="navWallet"> Wallet</span>
                  </button>
                </>
              ) : (
                <section>
                  <div className="ChainGroupButton">
                    {chain?.id === 8453 ? (
                      <Link to={userProfile} className="navConnectButton userProfileButton">
                        <img
                          src={bannerPreview}
                          alt=""
                        />
                        {userName}
                        &nbsp;
                        <img
                          src={arrow}
                          alt=""
                        />
                      </Link>
                    ) : (
                      <button
                        className="navConnectButton"
                        type="submit"
                        onClick={() => switchNetwork?.(8453)}
                      >
                        {'To Base'}
                        {isLoading && pendingChainId === 8453 && ''}
                      </button>
                    )}
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
        {isMobile() ?
          <div>
            <img src={rocketImage} alt="rocket logo" className="rocket" />
            {sortedList.map(({ name, address, trending }, i) => (
              <a className="top-bar-text" href={'/buy/?address=' + address} target='_blank' rel="noreferrer">#{name}</a>
            ))}
          </div> :
          <>
          </>
        }
      </div>
    </div>
  )
}

export default TopBar
