import React from 'react';
import { Link } from 'react-router-dom';
import MoonHeader from '../icons/MainHeader.png';

const MoonHeaderLink = ({ className }) => {
  return (
   <Link to="/AllLaunches">
     <img src={MoonHeader} alt="logo" className={className} />
   </Link>
  );
};

export default MoonHeaderLink;