/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'
import { useAccount } from 'wagmi'
import '../App.css'
import '../styles/MainContainer.css'
import { useWeb3Modal } from '@web3modal/react'
import Footer from '../components/Footer'
import { ReactComponent as BackIcon } from '../icons/back.svg'
import { ReactComponent as DownloadIcon } from '../icons/download_icon.svg'
import TopBar from '../components/TopBar'
import { Link } from 'react-router-dom'

const Profile = () => {
  let [userAddress] = useQueryParam('address', StringParam)
  const { address, isConnected } = useAccount()
  const history = useHistory()
  const [, setFirstConnect] = useState(false)
  const { open } = useWeb3Modal()

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  const [userName, setUserName] = useState('')
  const [userBio, setUserBio] = useState('')
  const [bannerPreview, setBannerPreview] = useState('')

  useEffect(() => {
    const FetchInfo = async () => {
      try {
        await fetch(`https://base-fairlaunch-api-0df02e9f3f15.herokuapp.com/api/getOne/${userAddress}`, {
          method: 'GET'
        })
          .then(async res => {
            let data = await res.json()
            if (data.length > 0) {
              if (data[0].imageUrl === 'default') {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              } else {
                setBannerPreview('https://moonbase.zapto.org:13001/uploads/' + data[0].imageUrl)
              }
              setUserName(data[0].name)
              setUserBio(data[0].content)
            } else {
              setBannerPreview('https://moonbase.zapto.org:13001/uploads/1719477883921-logo-moonboy67.png')
              setUserName('@mooning')
              setUserBio('Just mooning it out')
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (userAddress) {
      FetchInfo()
    }
  }, [userAddress])

  return (
    <div>

      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar"></div>
            <div className="headerMargin" />
            <section className="ProfileBox">
              <>
                <br />
                <div className="claim-card">
                  <div style={{ textAlign: 'left' }}>
                    <button
                      className="back-button"
                      onClick={() => history.goBack()}
                    >
                      <BackIcon className="back-icon" />
                      &nbsp;Back
                    </button>
                  </div>
                  <div className="user-details-wrapper">
                    <div className="profile-user-avatar-wrapper">
                      <div className="profile-user-avatar">
                        <img
                          src={bannerPreview}
                          alt=""
                          className="profile-user-avatar-image"
                        />
                      </div>
                    </div>

                    <div>
                      <p className="profile-user-name">{userName}</p>
                      <p className="profile-user-bio">{userBio}</p>
                      {address === userAddress ?
                        <Link to="/editprofile" className="edit-profile-button">
                          Edit profile&nbsp;
                          <DownloadIcon className="back-icon" />
                        </Link>
                        :
                        <></>
                      }
                    </div>

                  </div>
                  <br />
                  {isConnected ?
                    <div
                      className="profile-wallet-address"
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {userAddress ? userAddress : '\u00A0'}
                    </div>
                    :
                    <></>
                  }

                  <div style={{ textAlign: 'center' }}>
                    {
                      isConnected ?
                        <button
                          className="connect-wallet"
                          type="submit"
                          onClick={() => {
                            onConnectWallet()
                          }}
                          style={{ fontSize: '20px' }}
                        >
                          Disconnect Wallet
                        </button>
                        :
                        <button
                          className="connect-wallet"
                          type="submit"
                          onClick={() => {
                            onConnectWallet()
                          }}
                          style={{ fontSize: '20px' }}
                        >
                          Connect Wallet
                        </button>
                    }
                  </div>
                  <br />
                </div>
                <br />
              </>
            </section>
          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default Profile
