/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useNetwork } from 'wagmi'
import { useHistory } from 'react-router-dom'
import '../App.css'
import MultiCallAbi from '../config/MultiCallAbi.json'
import '../styles/MainContainer.css'
import CoinCard from '../components/CoinCard'
import ClipLoader from 'react-spinners/ClipLoader'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import { Link } from 'react-router-dom'

const publicClient = createPublicClient({
  chain: base,
  transport: http()
})
const App = () => {
  const multicallAddress = '0x4Ec30E9D706c881b0B6D67929025C77bA473214D'
  const { chain } = useNetwork()
  let [loading, setLoading] = useState(false)

  const [coinLists, setCoinListDatas] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [currentLength, setCurrentLength] = useState(0)
  const [totalLength, setTotalLength] = useState(0)

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true)
        const mainInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'coinInfo'
        })
        const otherInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'coinOtherInfo'
        })
        const length = Number(mainInfo[0].length)
        setTotalLength(length)
        if (length > 0) {
          let coinListsDatas = []
          let coinData
          let last
          if (length > 6) {
            last = length - 6
            setCurrentLength(last)
          } else {
            last = 0
          }
          for (let i = length - 1; i >= last; i--) {
            if (otherInfo[0][i] === true) {
              let bannerUrl =
                'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-banner.png'
              let logoUrl =
                'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-logo.png'
              let lpBurnAmount = false
              if (Number(otherInfo[1][i]) > 9900) {
                lpBurnAmount = true
              }
              coinData = {
                address: otherInfo[2][i],
                name: mainInfo[0][i],
                logoUrl: logoUrl,
                bannerUrl: bannerUrl,
                lpBurnAmount: lpBurnAmount,
                info: mainInfo[5][i],
                state: 1,
                lpETHAmounts: Number(mainInfo[4][i]) / (10 ** 18)
              }
              coinListsDatas.push(coinData)
            } else {
              setLoading(false)
            }
          }
          setCoinListDatas(coinListsDatas)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    FetchData()
  }, [chain?.id])

  const loadMoreLists = () => async () => {
    try {
      setLoading(true)
      const length = totalLength
      if (length > 0) {
        let coinListsDatas = []
        let coinData
        let last
        if (currentLength > 0) {
          if (currentLength > 6) {
            last = currentLength - 6
          } else {
            last = 0
          }
          setCurrentLength(last)
          const mainInfo = await publicClient.readContract({
            address: multicallAddress,
            abi: MultiCallAbi,
            functionName: 'coinInfo'
          })
          const otherInfo = await publicClient.readContract({
            address: multicallAddress,
            abi: MultiCallAbi,
            functionName: 'coinOtherInfo'
          })
          const length = Number(mainInfo[0].length)
          for (let i = length - 1; i >= last; i--) {
            if (otherInfo[0][i] === true) {
              let bannerUrl =
                'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-banner.png'
              let logoUrl =
                'https://moonbase.zapto.org:13001/uploads/' + otherInfo[2][i] + '-logo.png'
              let lpBurnAmount = false
              if (Number(otherInfo[1][i]) > 9900) {
                lpBurnAmount = true
              }
              coinData = {
                address: otherInfo[2][i],
                name: mainInfo[0][i],
                logoUrl: logoUrl,
                bannerUrl: bannerUrl,
                lpBurnAmount: lpBurnAmount,
                info: mainInfo[5][i],
                state: 1,
                lpETHAmounts: Number(mainInfo[4][i]) / (10 ** 18)
              }
              coinListsDatas.push(coinData)
            } else {
              setLoading(false)
            }
          }
          let newDatas = coinLists.concat(coinListsDatas)
          setCoinListDatas(newDatas)
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const launchTypes = ["Stealth Launches", "All Launches", "Fair Launches"];
  const statusFilters = ["Status", "Upcoming", "Sale Live", "Closed", "Live"];
  const [statusFilter, setStatusFilter] = useState("Status");
  const history = useHistory();

  const handleLaunchTypeChange = type => {
    const paths = {
      'all launches': '/AllLaunches',
      'fair launches': '/FairLaunches',
      'stealth launches': '/CommunityFunded',
      'my contributions': '/MyContributions'
    }

    const path = paths[type.toLowerCase()]
    if (path) {
      history.push(path)
    }
  }

  const handleStatusFilterChange = status => {
    setStatusFilter(status)
  }

  const shouldRenderCard = item => {
    if (statusFilter.toLocaleLowerCase() === 'status') {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'upcoming' &&
      item.state === 0
    ) {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'live' &&
      item.state === 1
    ) {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'closed' &&
      item.state === 2
    ) {
      return true
    } else if (
      statusFilter.toLocaleLowerCase() === 'sale live' &&
      item.state === 3
    ) {
      return true
    }
    return false
  }
  function isMobile() {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  }

  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="navBar"></div>

          <div className="headerMargin" />
          <div className="MainDashboard">
            <>
              <section className="FairLaunchFlexLayout">
                <section className="">
                  <p style={{ textAlign: 'center' }}>
                    <Link to="/FairLaunch" className="create-token-button">
                      {/* Create&nbsp;Fair&nbsp;Launch */}
                      Create&nbsp;Fairlaunch
                    </Link>
                  </p>
                  <br />
                  {/* Search field */}
                  <section>
                    <div className="filter-row-offset">
                      <div className="filter-row">
                        <div className="filter-buttons-row">
                          <div className="filter-dropdown-row">
                            <select
                              className="launch-type-dropdown"
                              styles={{
                                control: styles => ({
                                  ...styles,
                                  backgroundColor: '#06021d',
                                  color: 'white'
                                }),
                                option: styles => ({
                                  ...styles,
                                  backgroundColor: '#3554A2',
                                  color: 'white'
                                }),
                                singleValue: styles => ({
                                  ...styles,
                                  color: 'white'
                                }),
                                input: styles => ({
                                  ...styles,
                                  caretColor: 'transparent'
                                }),
                                dropdownIndicator: styles => ({
                                  ...styles,
                                  color: '9900c8'
                                }),
                                placeholder: styles => ({
                                  gridArea: '1 / 1 / 2 / 3',
                                  color: '#777777',
                                  marginLeft: '2px',
                                  marginRight: '2px',
                                  boxSizing: 'border-box'
                                })
                              }}
                              onChange={e =>
                                handleLaunchTypeChange(e.target.value)
                              }
                            >
                              {launchTypes.map((type, index) => (
                                <option key={index} value={type.toLowerCase()}>
                                  {type}
                                </option>
                              ))}
                            </select>
                            <select
                              className="status-filter-dropdown"
                              onChange={e =>
                                handleStatusFilterChange(e.target.value)
                              }
                            >
                              {statusFilters.map((status, index) => (
                                <option
                                  key={index}
                                  value={status.toLowerCase()}
                                >
                                  {status}
                                </option>
                              ))}
                            </select>
                          </div>
                          {!isMobile() ?
                            <Link
                              to="/MyContributions"
                              className="my-contributions-button"
                            >
                              My&nbsp;Contributions
                            </Link>
                            :
                            <></>
                          }
                        </div>
                        <div className="search-field">
                          <SearchIcon className="search-field-icon" />
                          <input
                            className="searchField search-field-input"
                            type="text"
                            placeholder="Search token"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </div>
                  </section>

                  {coinLists.length > 0 ? (
                    <>
                      <div className="launchpad-card-grid">
                        {coinLists
                          .filter(item => shouldRenderCard(item))
                          .filter(coinData =>
                            coinData.name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map(
                            (
                              {
                                address,
                                name,
                                logoUrl,
                                bannerUrl,
                                lpBurnAmount,
                                info,
                                lpETHAmounts
                              },
                              i
                            ) => (
                              <CoinCard
                                tokenAddress={address}
                                tokenName={name}
                                LogoUrl={
                                  <img
                                    src={logoUrl}
                                    className="claim-eth-logo"
                                  />
                                }
                                BannerUrl={bannerUrl}
                                LpBurnAmount={lpBurnAmount}
                                Info={info}
                                LpETHAmount={lpETHAmounts}
                              />
                            )
                          )
                        }
                      </div>
                      {
                        coinLists
                          .filter(item => !shouldRenderCard(item))
                          .map(
                            () => (
                              <div className="loadingBox">
                                <p className="Nodatas Text1">
                                  No datas yet
                                </p>
                              </div>
                            )
                          )
                      }
                      {loading === true ? (
                        <div className="loadingBox">
                          <ClipLoader
                            color={'#afccc6'}
                            loading={true}
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : currentLength > 0 ? (
                        <p className="loadMoreText" onClick={loadMoreLists()}>
                          Load more ...
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : totalLength === 0 || totalLength > 0 ? (
                    <div className="loadingBox">
                      <p className="Nodatas Text1">
                        No datas yet
                      </p>
                    </div>
                  ) : (
                    <div className="EmptyLaunchpad">
                      <div className="loadingBox">
                        <p className="Text1" style={{ color: 'black' }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={'#afccc6'}
                          loading={true}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </div>
                  )}
                  <br />
                  <br />
                  <br />
                  <br />
                </section>
              </section>
            </>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
