import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const LeaderCard = ({
    tokenAddress,
    tokenName,
    LogoUrl,
    liquidity,
    priceChange
}) => {
    const link = `/token/?address=${tokenAddress}`
    return (
        <div className='LeaderBox'>
            <div className="leaderboard-eth-logo-container">
                {LogoUrl}
                <div className="sub-leaderbox">
                    <p className="launchpad-token-name" >{tokenName}</p>
                    <div>
                        <p className="leaderbox-info" >LP: $ {(liquidity / 1000).toFixed(2)} k</p>
                        <p className="leaderbox-info" >Price Change: {priceChange} %</p>
                    </div>   
                </div>
            </div>
            <br />
            <div className="launchpad-bottom-row">
                <Link className="launchpad-details-button" to={link}>
                    <span className="launchpad-details-button-text">Details</span>
                </Link>
            </div>
        </div>
    )
}

LeaderCard.propTypes = {
    tokenName: PropTypes.string.isRequired,
    tokenAddress: PropTypes.string.isRequired
}

LeaderCard.defaultProps = {
    RugProof: false,
}

export default LeaderCard
